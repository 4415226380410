
var ortWasmThreaded = (() => {
  var _scriptDir = typeof document != 'undefined' ? document.currentScript?.src : undefined;
  if (typeof __filename != 'undefined') _scriptDir ||= __filename;
  return (
function(moduleArg = {}) {

function e(){m.buffer!=q.buffer&&t();return q}function v(){m.buffer!=q.buffer&&t();return aa}function ba(){m.buffer!=q.buffer&&t();return ca}function da(){m.buffer!=q.buffer&&t();return ea}function z(){m.buffer!=q.buffer&&t();return fa}function A(){m.buffer!=q.buffer&&t();return ha}function ia(){m.buffer!=q.buffer&&t();return ja}function ka(){m.buffer!=q.buffer&&t();return la}var B=moduleArg,ma,na,readyPromise=new Promise((a,b)=>{ma=a;na=b});"use strict";
B.mountExternalData=(a,b)=>{(B.Db||(B.Db=new Map)).set(a,b)};B.unmountExternalData=()=>{delete B.Db};
let pa=()=>{const a=(c,d,f)=>(...g)=>{const k=C,l=d?.();g=c(...g);const p=d?.();l!==p&&(c=p,f(l),d=f=null);return C!=k?oa():g},b=c=>async(...d)=>{try{if(B.Cb)throw Error("Session already started");const f=B.Cb={ec:d[0],errors:[]},g=await c(...d);if(B.Cb!==f)throw Error("Session mismatch");B.Kb?.flush();const k=f.errors;if(0<k.length){let l=await Promise.all(k);l=l.filter(p=>p);if(0<l.length)throw Error(l.join("\n"));}return g}finally{B.Cb=null}};B._OrtCreateSession=a(B._OrtCreateSession,()=>B._OrtCreateSession,
c=>B._OrtCreateSession=c);B._OrtRun=b(a(B._OrtRun,()=>B._OrtRun,c=>B._OrtRun=c));B._OrtRunWithBinding=b(a(B._OrtRunWithBinding,()=>B._OrtRunWithBinding,c=>B._OrtRunWithBinding=c));B._OrtBindInput=a(B._OrtBindInput,()=>B._OrtBindInput,c=>B._OrtBindInput=c);pa=void 0};
B.jsepInit=(a,b)=>{pa?.();if("webgpu"===a){[B.Kb,B.Wb,B.$b,B.Lb,B.Zb,B.ob,B.ac,B.cc,B.Xb,B.Yb,B.bc]=b;const c=B.Kb;B.jsepRegisterBuffer=(d,f,g,k)=>c.registerBuffer(d,f,g,k);B.jsepGetBuffer=d=>c.getBuffer(d);B.jsepCreateDownloader=(d,f,g)=>c.createDownloader(d,f,g);B.jsepOnReleaseSession=d=>{c.onReleaseSession(d)};B.jsepOnRunStart=d=>c.onRunStart(d)}};
var qa=Object.assign({},B),ra="./this.program",sa=(a,b)=>{throw b;},ta="object"==typeof window,D="function"==typeof importScripts,E="object"==typeof process&&"object"==typeof process.versions&&"string"==typeof process.versions.node,F=B.ENVIRONMENT_IS_PTHREAD||!1,G="";function ua(a){return B.locateFile?B.locateFile(a,G):G+a}var va,wa,xa;
if(E){var fs=require("fs"),ya=require("path");G=D?ya.dirname(G)+"/":__dirname+"/";va=(a,b)=>{a=za(a)?new URL(a):ya.normalize(a);return fs.readFileSync(a,b?void 0:"utf8")};xa=a=>{a=va(a,!0);a.buffer||(a=new Uint8Array(a));return a};wa=(a,b,c,d=!0)=>{a=za(a)?new URL(a):ya.normalize(a);fs.readFile(a,d?void 0:"utf8",(f,g)=>{f?c(f):b(d?g.buffer:g)})};!B.thisProgram&&1<process.argv.length&&(ra=process.argv[1].replace(/\\/g,"/"));process.argv.slice(2);sa=(a,b)=>{process.exitCode=a;throw b;};global.Worker=
require("worker_threads").Worker}else if(ta||D)D?G=self.location.href:"undefined"!=typeof document&&document.currentScript&&(G=document.currentScript.src),(typeof _scriptDir !== "undefined" && _scriptDir)&&(G=_scriptDir),G.startsWith("blob:")?G="":G=G.substr(0,G.replace(/[?#].*/,"").lastIndexOf("/")+1),E||(va=a=>{var b=new XMLHttpRequest;b.open("GET",a,!1);b.send(null);return b.responseText},D&&(xa=a=>{var b=new XMLHttpRequest;b.open("GET",a,!1);b.responseType="arraybuffer";b.send(null);return new Uint8Array(b.response)}),wa=(a,b,c)=>
{var d=new XMLHttpRequest;d.open("GET",a,!0);d.responseType="arraybuffer";d.onload=()=>{200==d.status||0==d.status&&d.response?b(d.response):c()};d.onerror=c;d.send(null)});E&&"undefined"==typeof performance&&(global.performance=require("perf_hooks").performance);var Aa=console.log.bind(console),Ba=console.error.bind(console);E&&(Aa=(...a)=>fs.writeSync(1,a.join(" ")+"\n"),Ba=(...a)=>fs.writeSync(2,a.join(" ")+"\n"));var Ca=Aa,H=Ba;Object.assign(B,qa);qa=null;
var m,Da,I=!1,J,q,aa,ca,ea,fa,ha,ja,K,Ea,la;function t(){var a=m.buffer;B.HEAP8=q=new Int8Array(a);B.HEAP16=ca=new Int16Array(a);B.HEAPU8=aa=new Uint8Array(a);B.HEAPU16=ea=new Uint16Array(a);B.HEAP32=fa=new Int32Array(a);B.HEAPU32=ha=new Uint32Array(a);B.HEAPF32=ja=new Float32Array(a);B.HEAPF64=la=new Float64Array(a);B.HEAP64=K=new BigInt64Array(a);B.HEAPU64=Ea=new BigUint64Array(a)}var Fa=16777216;
if(F)m=B.wasmMemory;else if(B.wasmMemory)m=B.wasmMemory;else if(m=new WebAssembly.Memory({initial:Fa/65536,maximum:65536,shared:!0}),!(m.buffer instanceof SharedArrayBuffer))throw H("requested a shared WebAssembly.Memory but the returned buffer is not a SharedArrayBuffer, indicating that while the browser has SharedArrayBuffer it does not have WebAssembly threads support - you may need to set a flag"),E&&H("(on node you may need: --experimental-wasm-threads --experimental-wasm-bulk-memory and/or recent version)"),
Error("bad memory");t();Fa=m.buffer.byteLength;var Ga=[],Ha=[],Ia=[],L=0,Ja=null,Ka=null;function La(){L--;if(0==L&&(null!==Ja&&(clearInterval(Ja),Ja=null),Ka)){var a=Ka;Ka=null;a()}}function Ma(a){a="Aborted("+a+")";H(a);I=!0;J=1;a=new WebAssembly.RuntimeError(a+". Build with -sASSERTIONS for more info.");na(a);throw a;}var Na=a=>a.startsWith("data:application/octet-stream;base64,"),za=a=>a.startsWith("file://"),Oa;Oa="ort-wasm-simd-threaded.wasm";Na(Oa)||(Oa=ua(Oa));
function Pa(a){if(xa)return xa(a);throw"both async and sync fetching of the wasm failed";}function Qa(a){if(ta||D){if("function"==typeof fetch&&!za(a))return fetch(a,{credentials:"same-origin"}).then(b=>{if(!b.ok)throw`failed to load wasm binary file at '${a}'`;return b.arrayBuffer()}).catch(()=>Pa(a));if(wa)return new Promise((b,c)=>{wa(a,d=>b(new Uint8Array(d)),c)})}return Promise.resolve().then(()=>Pa(a))}
function Ra(a,b,c){return Qa(a).then(d=>WebAssembly.instantiate(d,b)).then(c,d=>{H(`failed to asynchronously prepare wasm: ${d}`);Ma(d)})}function Sa(a,b){var c=Oa;return"function"!=typeof WebAssembly.instantiateStreaming||Na(c)||za(c)||E||"function"!=typeof fetch?Ra(c,a,b):fetch(c,{credentials:"same-origin"}).then(d=>WebAssembly.instantiateStreaming(d,a).then(b,function(f){H(`wasm streaming compile failed: ${f}`);H("falling back to ArrayBuffer instantiation");return Ra(c,a,b)}))}
var Ta={826468:(a,b,c,d)=>{if("undefined"==typeof B||!B.Db)return 1;a=M(a>>>0);a.startsWith("./")&&(a=a.substring(2));a=B.Db.get(a);if(!a)return 2;b>>>=0;c>>>=0;d>>>=0;if(b+c>a.byteLength)return 3;try{return v().set(a.subarray(b,b+c),d>>>0),0}catch{return 4}},826969:()=>{B.Xb()},827E3:()=>{B.Yb()},827029:()=>{B.bc()},827054:a=>B.Wb(a),827087:a=>B.$b(a),827119:(a,b,c)=>{B.Lb(a,b,c,!0)},827158:(a,b,c)=>{B.Lb(a,b,c)},827191:a=>{B.ob("Abs",a,void 0)},827242:a=>{B.ob("Neg",a,void 0)},827293:a=>{B.ob("Floor",
a,void 0)},827346:a=>{B.ob("Ceil",a,void 0)},827398:a=>{B.ob("Reciprocal",a,void 0)},827456:a=>{B.ob("Sqrt",a,void 0)},827508:a=>{B.ob("Exp",a,void 0)},827559:a=>{B.ob("Erf",a,void 0)},827610:a=>{B.ob("Sigmoid",a,void 0)},827665:(a,b,c)=>{B.ob("HardSigmoid",a,{alpha:b,beta:c})},827744:a=>{B.ob("Log",a,void 0)},827795:a=>{B.ob("Sin",a,void 0)},827846:a=>{B.ob("Cos",a,void 0)},827897:a=>{B.ob("Tan",a,void 0)},827948:a=>{B.ob("Asin",a,void 0)},828E3:a=>{B.ob("Acos",a,void 0)},828052:a=>{B.ob("Atan",
a,void 0)},828104:a=>{B.ob("Sinh",a,void 0)},828156:a=>{B.ob("Cosh",a,void 0)},828208:a=>{B.ob("Asinh",a,void 0)},828261:a=>{B.ob("Acosh",a,void 0)},828314:a=>{B.ob("Atanh",a,void 0)},828367:a=>{B.ob("Tanh",a,void 0)},828419:a=>{B.ob("Not",a,void 0)},828470:(a,b,c)=>{B.ob("Clip",a,{min:b,max:c})},828539:a=>{B.ob("Clip",a,void 0)},828591:(a,b)=>{B.ob("Elu",a,{alpha:b})},828649:a=>{B.ob("Relu",a,void 0)},828701:(a,b)=>{B.ob("LeakyRelu",a,{alpha:b})},828765:(a,b)=>{B.ob("ThresholdedRelu",a,{alpha:b})},
828835:(a,b)=>{B.ob("Cast",a,{to:b})},828893:a=>{B.ob("Add",a,void 0)},828944:a=>{B.ob("Sub",a,void 0)},828995:a=>{B.ob("Mul",a,void 0)},829046:a=>{B.ob("Div",a,void 0)},829097:a=>{B.ob("Pow",a,void 0)},829148:a=>{B.ob("Equal",a,void 0)},829201:a=>{B.ob("Greater",a,void 0)},829256:a=>{B.ob("GreaterOrEqual",a,void 0)},829318:a=>{B.ob("Less",a,void 0)},829370:a=>{B.ob("LessOrEqual",a,void 0)},829429:(a,b,c,d,f)=>{B.ob("ReduceMean",a,{keepDims:!!b,noopWithEmptyAxes:!!c,axes:d?Array.from(z().subarray(d>>>
0,f>>>0)):[]})},829588:(a,b,c,d,f)=>{B.ob("ReduceMax",a,{keepDims:!!b,noopWithEmptyAxes:!!c,axes:d?Array.from(z().subarray(d>>>0,f>>>0)):[]})},829746:(a,b,c,d,f)=>{B.ob("ReduceMin",a,{keepDims:!!b,noopWithEmptyAxes:!!c,axes:d?Array.from(z().subarray(d>>>0,f>>>0)):[]})},829904:(a,b,c,d,f)=>{B.ob("ReduceProd",a,{keepDims:!!b,noopWithEmptyAxes:!!c,axes:d?Array.from(z().subarray(d>>>0,f>>>0)):[]})},830063:(a,b,c,d,f)=>{B.ob("ReduceSum",a,{keepDims:!!b,noopWithEmptyAxes:!!c,axes:d?Array.from(z().subarray(d>>>
0,f>>>0)):[]})},830221:(a,b,c,d,f)=>{B.ob("ReduceL1",a,{keepDims:!!b,noopWithEmptyAxes:!!c,axes:d?Array.from(z().subarray(d>>>0,f>>>0)):[]})},830378:(a,b,c,d,f)=>{B.ob("ReduceL2",a,{keepDims:!!b,noopWithEmptyAxes:!!c,axes:d?Array.from(z().subarray(d>>>0,f>>>0)):[]})},830535:(a,b,c,d,f)=>{B.ob("ReduceLogSum",a,{keepDims:!!b,noopWithEmptyAxes:!!c,axes:d?Array.from(z().subarray(d>>>0,f>>>0)):[]})},830696:(a,b,c,d,f)=>{B.ob("ReduceSumSquare",a,{keepDims:!!b,noopWithEmptyAxes:!!c,axes:d?Array.from(z().subarray(d>>>
0,f>>>0)):[]})},830860:(a,b,c,d,f)=>{B.ob("ReduceLogSumExp",a,{keepDims:!!b,noopWithEmptyAxes:!!c,axes:d?Array.from(z().subarray(d>>>0,f>>>0)):[]})},831024:a=>{B.ob("Where",a,void 0)},831077:(a,b,c)=>{B.ob("Transpose",a,{perm:b?Array.from(z().subarray(b>>>0,c>>>0)):[]})},831185:(a,b,c,d)=>{B.ob("DepthToSpace",a,{blocksize:b,mode:M(c),format:d?"NHWC":"NCHW"})},831318:(a,b,c,d)=>{B.ob("DepthToSpace",a,{blocksize:b,mode:M(c),format:d?"NHWC":"NCHW"})},831451:(a,b,c,d,f,g,k,l,p,n,r,x,y,h,u)=>{B.ob("ConvTranspose",
a,{format:p?"NHWC":"NCHW",autoPad:b,dilations:[c],group:d,kernelShape:[f],pads:[g,k],strides:[l],wIsConst:()=>!!e()[n>>>0],outputPadding:r?Array.from(z().subarray(r>>>0,x>>>0)):[],outputShape:y?Array.from(z().subarray(y>>>0,h>>>0)):[],activation:M(u)})},831852:(a,b,c,d,f,g,k,l,p,n,r,x,y,h)=>{B.ob("ConvTranspose",a,{format:l?"NHWC":"NCHW",autoPad:b,dilations:Array.from(z().subarray(c>>>0,(c>>>0)+2>>>0)),group:d,kernelShape:Array.from(z().subarray(f>>>0,(f>>>0)+2>>>0)),pads:Array.from(z().subarray(g>>>
0,(g>>>0)+4>>>0)),strides:Array.from(z().subarray(k>>>0,(k>>>0)+2>>>0)),wIsConst:()=>!!e()[p>>>0],outputPadding:n?Array.from(z().subarray(n>>>0,r>>>0)):[],outputShape:x?Array.from(z().subarray(x>>>0,y>>>0)):[],activation:M(h)})},832417:(a,b,c,d,f,g,k,l,p,n,r,x,y,h,u)=>{B.ob("ConvTranspose",a,{format:p?"NHWC":"NCHW",autoPad:b,dilations:[c],group:d,kernelShape:[f],pads:[g,k],strides:[l],wIsConst:()=>!!e()[n>>>0],outputPadding:r?Array.from(z().subarray(r>>>0,x>>>0)):[],outputShape:y?Array.from(z().subarray(y>>>
0,h>>>0)):[],activation:M(u)})},832818:(a,b,c,d,f,g,k,l,p,n,r,x,y,h)=>{B.ob("ConvTranspose",a,{format:l?"NHWC":"NCHW",autoPad:b,dilations:Array.from(z().subarray(c>>>0,(c>>>0)+2>>>0)),group:d,kernelShape:Array.from(z().subarray(f>>>0,(f>>>0)+2>>>0)),pads:Array.from(z().subarray(g>>>0,(g>>>0)+4>>>0)),strides:Array.from(z().subarray(k>>>0,(k>>>0)+2>>>0)),wIsConst:()=>!!e()[p>>>0],outputPadding:n?Array.from(z().subarray(n>>>0,r>>>0)):[],outputShape:x?Array.from(z().subarray(x>>>0,y>>>0)):[],activation:M(h)})},
833383:(a,b)=>{B.ob("GlobalAveragePool",a,{format:b?"NHWC":"NCHW"})},833474:(a,b,c,d,f,g,k,l,p,n,r,x,y,h,u,w)=>{B.ob("AveragePool",a,{format:w?"NHWC":"NCHW",auto_pad:b,ceil_mode:c,count_include_pad:d,storage_order:f,dilations:[g,k],kernel_shape:[l,p],pads:[n,r,x,y],strides:[h,u]})},833758:(a,b)=>{B.ob("GlobalAveragePool",a,{format:b?"NHWC":"NCHW"})},833849:(a,b,c,d,f,g,k,l,p,n,r,x,y,h,u,w)=>{B.ob("AveragePool",a,{format:w?"NHWC":"NCHW",auto_pad:b,ceil_mode:c,count_include_pad:d,storage_order:f,dilations:[g,
k],kernel_shape:[l,p],pads:[n,r,x,y],strides:[h,u]})},834133:(a,b)=>{B.ob("GlobalMaxPool",a,{format:b?"NHWC":"NCHW"})},834220:(a,b,c,d,f,g,k,l,p,n,r,x,y,h,u,w)=>{B.ob("MaxPool",a,{format:w?"NHWC":"NCHW",auto_pad:b,ceil_mode:c,count_include_pad:d,storage_order:f,dilations:[g,k],kernel_shape:[l,p],pads:[n,r,x,y],strides:[h,u]})},834500:(a,b)=>{B.ob("GlobalMaxPool",a,{format:b?"NHWC":"NCHW"})},834587:(a,b,c,d,f,g,k,l,p,n,r,x,y,h,u,w)=>{B.ob("MaxPool",a,{format:w?"NHWC":"NCHW",auto_pad:b,ceil_mode:c,
count_include_pad:d,storage_order:f,dilations:[g,k],kernel_shape:[l,p],pads:[n,r,x,y],strides:[h,u]})},834867:(a,b,c,d,f)=>{B.ob("Gemm",a,{alpha:b,beta:c,transA:d,transB:f})},834971:a=>{B.ob("MatMul",a,void 0)},835025:(a,b,c,d)=>{B.ob("ArgMax",a,{keepDims:!!b,selectLastIndex:!!c,axis:d})},835133:(a,b,c,d)=>{B.ob("ArgMin",a,{keepDims:!!b,selectLastIndex:!!c,axis:d})},835241:(a,b)=>{B.ob("Softmax",a,{axis:b})},835304:(a,b)=>{B.ob("Concat",a,{axis:b})},835364:(a,b,c,d,f)=>{B.ob("Split",a,{axis:b,numOutputs:c,
splitSizes:d?Array.from(z().subarray(d>>>0,f>>>0)):[]})},835504:a=>{B.ob("Expand",a,void 0)},835558:(a,b)=>{B.ob("Gather",a,{axis:Number(b)})},835629:(a,b)=>{B.ob("GatherElements",a,{axis:Number(b)})},835708:(a,b,c,d,f,g,k,l,p,n,r)=>{B.ob("Resize",a,{antialias:b,axes:c?Array.from(z().subarray(c>>>0,d>>>0)):[],coordinateTransformMode:M(f),cubicCoeffA:g,excludeOutside:k,extrapolationValue:l,keepAspectRatioPolicy:M(p),mode:M(n),nearestMode:M(r)})},836054:(a,b,c,d,f,g,k)=>{B.ob("Slice",a,{starts:b?Array.from(z().subarray(b>>>
0,c>>>0)):[],ends:d?Array.from(z().subarray(d>>>0,f>>>0)):[],axes:g?Array.from(z().subarray(g>>>0,k>>>0)):[]})},836270:a=>{B.ob("Tile",a,void 0)},836322:(a,b,c,d)=>{B.ob("LayerNormalization",a,{axis:b,epsilon:c,simplified:!!d})},836433:(a,b,c)=>{B.ob("InstanceNormalization",a,{epsilon:b,format:c?"NHWC":"NCHW"})},836547:(a,b,c)=>{B.ob("InstanceNormalization",a,{epsilon:b,format:c?"NHWC":"NCHW"})},836661:a=>{B.ob("Range",a,void 0)},836714:(a,b)=>{B.ob("Einsum",a,{equation:M(b)})},836795:(a,b,c,d,f)=>
{B.ob("Pad",a,{mode:b,value:c,pads:d?Array.from(z().subarray(d>>>0,f>>>0)):[]})},836922:(a,b,c,d,f,g)=>{B.ob("BatchNormalization",a,{epsilon:b,momentum:c,spatial:!!f,trainingMode:!!d,format:g?"NHWC":"NCHW"})},837091:(a,b,c,d,f,g)=>{B.ob("BatchNormalization",a,{epsilon:b,momentum:c,spatial:!!f,trainingMode:!!d,format:g?"NHWC":"NCHW"})},837260:(a,b,c)=>{B.ob("CumSum",a,{exclusive:Number(b),reverse:Number(c)})},837357:(a,b,c,d,f,g,k,l,p)=>{B.ob("Attention",a,{numHeads:b,isUnidirectional:c,maskFilterValue:d,
scale:f,doRotary:g,qkvHiddenSizes:k?Array.from(z().subarray(Number(l)>>>0,Number(l)+k>>>0)):[],pastPresentShareBuffer:!!p})},837629:a=>{B.ob("BiasAdd",a,void 0)},837684:a=>{B.ob("BiasSplitGelu",a,void 0)},837745:a=>{B.ob("FastGelu",a,void 0)},837801:(a,b,c,d,f,g,k,l,p,n,r,x,y)=>{B.ob("Conv",a,{format:p?"NHWC":"NCHW",auto_pad:b,dilations:[c],group:d,kernel_shape:[f],pads:g?Array.from(z().subarray(g>>>0,k>>>0)):[],strides:[l],w_is_const:()=>!!e()[n>>>0],activation:M(r),activation_params:x?Array.from(ia().subarray(x>>>
0,y>>>0)):[]})},838171:(a,b,c,d,f,g,k,l,p,n,r,x,y,h,u,w)=>{B.ob("Conv",a,{format:x?"NHWC":"NCHW",auto_pad:b,dilations:[c,d],group:f,kernel_shape:[g,k],pads:l?Array.from(z().subarray(l>>>0,p>>>0)):[],strides:[n,r],w_is_const:()=>!!e()[y>>>0],activation:M(h),activation_params:u?Array.from(ia().subarray(u>>>0,w>>>0)):[]})},838562:a=>{B.ob("Gelu",a,void 0)},838614:(a,b,c,d,f,g)=>{B.ob("MatMulNBits",a,{k:b,n:c,accuracyLevel:d,bits:f,blockSize:g})},838741:(a,b,c,d,f,g)=>{B.ob("MultiHeadAttention",a,{numHeads:b,
isUnidirectional:c,maskFilterValue:d,scale:f,doRotary:g})},838900:(a,b,c,d,f)=>{B.ob("RotaryEmbedding",a,{interleaved:!!b,numHeads:c,rotaryEmbeddingDim:d,scale:f})},839039:(a,b,c)=>{B.ob("SkipLayerNormalization",a,{epsilon:b,simplified:!!c})},839141:(a,b,c)=>{B.ob("SkipLayerNormalization",a,{epsilon:b,simplified:!!c})},839243:(a,b,c,d)=>{B.ob("LayerNormalization",a,{axis:b,epsilon:c,simplified:!!d})},839354:a=>{B.ac(a)},839388:(a,b)=>B.cc(a,b,B.Cb.ec,B.Cb.errors)};
function Ua(a){this.name="ExitStatus";this.message=`Program terminated with exit(${a})`;this.status=a}
var Va=a=>{a.terminate();a.onmessage=()=>{}},Xa=a=>{0==N.xb.length&&(Wa(),N.Mb(N.xb[0]));var b=N.xb.pop();if(!b)return 6;N.yb.push(b);N.ub[a.wb]=b;b.wb=a.wb;var c={cmd:"run",start_routine:a.fc,arg:a.Rb,pthread_ptr:a.wb};E&&b.unref();b.postMessage(c,a.mc);return 0},O=0,$a=a=>{var b=Ya();a=a();Za(b);return a},P=(a,b,...c)=>$a(()=>{for(var d=2*c.length,f=ab(8*d),g=f>>>3,k=0;k<c.length;k++){var l=c[k];"bigint"==typeof l?(K[g+2*k]=1n,K[g+2*k+1]=l):(K[g+2*k]=0n,ka()[g+2*k+1>>>0]=l)}return bb(a,0,d,f,b)});
function cb(a){if(F)return P(0,1,a);J=a;0<O||(N.hc(),B.onExit?.(a),I=!0);sa(a,new Ua(a))}var eb=a=>{J=a;if(F)throw db(a),"unwind";cb(a)};function fb(){for(var a=B.numThreads;a--;)Wa();Ga.unshift(()=>{L++;gb(()=>La())})}function Wa(){var a=ua("ort-wasm-simd-threaded.worker.js");a=new Worker(a);N.xb.push(a)}function gb(a){F?a():Promise.all(N.xb.map(N.Mb)).then(a)}
var N={xb:[],yb:[],Qb:[],ub:{},Gb(){F?(N.receiveObjectTransfer=N.dc,N.threadInitTLS=N.Pb,N.setExitStatus=N.Ob):fb()},Ob:a=>J=a,pc:["$terminateWorker"],hc:()=>{for(var a of N.yb)Va(a);for(a of N.xb)Va(a);N.xb=[];N.yb=[];N.ub=[]},Nb:a=>{var b=a.wb;delete N.ub[b];N.xb.push(a);N.yb.splice(N.yb.indexOf(a),1);a.wb=0;hb(b)},dc(){},Pb(){N.Qb.forEach(a=>a())},Mb:a=>new Promise(b=>{a.onmessage=g=>{g=g.data;var k=g.cmd;if(g.targetThread&&g.targetThread!=ib()){var l=N.ub[g.targetThread];l?l.postMessage(g,g.transferList):
H(`Internal error! Worker sent a message "${k}" to target pthread ${g.targetThread}, but that thread no longer exists!`)}else if("checkMailbox"===k)jb();else if("spawnThread"===k)Xa(g);else if("cleanupThread"===k)N.Nb(N.ub[g.thread]);else if("killThread"===k)g=g.thread,k=N.ub[g],delete N.ub[g],Va(k),hb(g),N.yb.splice(N.yb.indexOf(k),1),k.wb=0;else if("cancelThread"===k)N.ub[g.thread].postMessage({cmd:"cancel"});else if("loaded"===k)a.loaded=!0,E&&!a.wb&&a.unref(),b(a);else if("alert"===k)alert(`Thread ${g.threadId}: ${g.text}`);
else if("setimmediate"===g.target)a.postMessage(g);else if("callHandler"===k)B[g.handler](...g.args);else k&&H(`worker sent an unknown command ${k}`)};a.onerror=g=>{H(`${"worker sent an error!"} ${g.filename}:${g.lineno}: ${g.message}`);throw g;};E&&(a.on("message",g=>a.onmessage({data:g})),a.on("error",g=>a.onerror(g)));var c=[],d=["onExit"],f;for(f of d)B.hasOwnProperty(f)&&c.push(f);a.postMessage({cmd:"load",handlers:c,urlOrBlob:B.mainScriptUrlOrBlob||_scriptDir,wasmMemory:m,wasmModule:Da})})};
B.PThread=N;var kb=a=>{for(;0<a.length;)a.shift()(B)};B.establishStackSpace=()=>{var a=ib(),b=A()[a+52>>>2>>>0];a=A()[a+56>>>2>>>0];lb(b,b-a);Za(b)};function db(a){if(F)return P(1,0,a);eb(a)}B.invokeEntryPoint=(a,b)=>{O=0;a=mb(a,b);0<O?N.Ob(a):nb(a)};class ob{constructor(a){this.Ib=a-24}Gb(a,b){A()[this.Ib+16>>>2>>>0]=0;A()[this.Ib+4>>>2>>>0]=a;A()[this.Ib+8>>>2>>>0]=b}}var pb=0,qb=0;function rb(a,b,c,d){return F?P(2,1,a,b,c,d):sb(a,b,c,d)}
function sb(a,b,c,d){a>>>=0;b>>>=0;c>>>=0;d>>>=0;if("undefined"==typeof SharedArrayBuffer)return H("Current environment does not support SharedArrayBuffer, pthreads are not available!"),6;var f=[];if(F&&0===f.length)return rb(a,b,c,d);a={fc:c,wb:a,Rb:d,mc:f};return F?(a.oc="spawnThread",postMessage(a,f),0):Xa(a)}
var tb="undefined"!=typeof TextDecoder?new TextDecoder("utf8"):void 0,ub=(a,b,c)=>{b>>>=0;var d=b+c;for(c=b;a[c]&&!(c>=d);)++c;if(16<c-b&&a.buffer&&tb)return tb.decode(a.buffer instanceof SharedArrayBuffer?a.slice(b,c):a.subarray(b,c));for(d="";b<c;){var f=a[b++];if(f&128){var g=a[b++]&63;if(192==(f&224))d+=String.fromCharCode((f&31)<<6|g);else{var k=a[b++]&63;f=224==(f&240)?(f&15)<<12|g<<6|k:(f&7)<<18|g<<12|k<<6|a[b++]&63;65536>f?d+=String.fromCharCode(f):(f-=65536,d+=String.fromCharCode(55296|f>>
10,56320|f&1023))}}else d+=String.fromCharCode(f)}return d},M=(a,b)=>(a>>>=0)?ub(v(),a,b):"";function vb(a,b,c){return F?P(3,1,a,b,c):0}function wb(a,b){if(F)return P(4,1,a,b)}
var xb=a=>{for(var b=0,c=0;c<a.length;++c){var d=a.charCodeAt(c);127>=d?b++:2047>=d?b+=2:55296<=d&&57343>=d?(b+=4,++c):b+=3}return b},yb=(a,b,c,d)=>{c>>>=0;if(!(0<d))return 0;var f=c;d=c+d-1;for(var g=0;g<a.length;++g){var k=a.charCodeAt(g);if(55296<=k&&57343>=k){var l=a.charCodeAt(++g);k=65536+((k&1023)<<10)|l&1023}if(127>=k){if(c>=d)break;b[c++>>>0]=k}else{if(2047>=k){if(c+1>=d)break;b[c++>>>0]=192|k>>6}else{if(65535>=k){if(c+2>=d)break;b[c++>>>0]=224|k>>12}else{if(c+3>=d)break;b[c++>>>0]=240|k>>
18;b[c++>>>0]=128|k>>12&63}b[c++>>>0]=128|k>>6&63}b[c++>>>0]=128|k&63}}b[c>>>0]=0;return c-f},zb=(a,b,c)=>yb(a,v(),b,c);function Ab(a,b){if(F)return P(5,1,a,b)}function Bb(a,b,c){if(F)return P(6,1,a,b,c)}function Cb(a,b,c){return F?P(7,1,a,b,c):0}function Db(a,b){if(F)return P(8,1,a,b)}function Eb(a,b,c){if(F)return P(9,1,a,b,c)}function Fb(a,b,c,d){if(F)return P(10,1,a,b,c,d)}function Gb(a,b,c,d){if(F)return P(11,1,a,b,c,d)}function Hb(a,b,c,d){if(F)return P(12,1,a,b,c,d)}
function Ib(a){if(F)return P(13,1,a)}function Jb(a,b){if(F)return P(14,1,a,b)}function Kb(a,b,c){if(F)return P(15,1,a,b,c)}var Lb,Q=a=>{for(var b="";v()[a>>>0];)b+=Lb[v()[a++>>>0]];return b},Mb={},Nb={},Ob={},R;function Pb(a,b,c={}){var d=b.name;if(!a)throw new R(`type "${d}" must have a positive integer typeid pointer`);if(Nb.hasOwnProperty(a)){if(c.Ub)return;throw new R(`Cannot register type '${d}' twice`);}Nb[a]=b;delete Ob[a];Mb.hasOwnProperty(a)&&(b=Mb[a],delete Mb[a],b.forEach(f=>f()))}
function S(a,b,c={}){if(!("argPackAdvance"in b))throw new TypeError("registerType registeredInstance requires argPackAdvance");return Pb(a,b,c)}var Qb=(a,b,c)=>{switch(b){case 1:return c?d=>e()[d>>>0]:d=>v()[d>>>0];case 2:return c?d=>ba()[d>>>1>>>0]:d=>da()[d>>>1>>>0];case 4:return c?d=>z()[d>>>2>>>0]:d=>A()[d>>>2>>>0];case 8:return c?d=>K[d>>>3]:d=>Ea[d>>>3];default:throw new TypeError(`invalid integer width (${b}): ${a}`);}},Rb=[],T=[];
function Sb(a){a>>>=0;9<a&&0===--T[a+1]&&(T[a]=void 0,Rb.push(a))}var U=a=>{if(!a)throw new R("Cannot use deleted val. handle = "+a);return T[a]},V=a=>{switch(a){case void 0:return 2;case null:return 4;case !0:return 6;case !1:return 8;default:const b=Rb.pop()||T.length;T[b]=a;T[b+1]=1;return b}};function Tb(a){return this.fromWireType(A()[a>>>2>>>0])}
var Ub={name:"emscripten::val",fromWireType:a=>{var b=U(a);Sb(a);return b},toWireType:(a,b)=>V(b),argPackAdvance:8,readValueFromPointer:Tb,Bb:null},Vb=(a,b)=>{switch(b){case 4:return function(c){return this.fromWireType(ia()[c>>>2>>>0])};case 8:return function(c){return this.fromWireType(ka()[c>>>3>>>0])};default:throw new TypeError(`invalid float width (${b}): ${a}`);}},Wb="undefined"!=typeof TextDecoder?new TextDecoder("utf-16le"):void 0,Xb=(a,b)=>{var c=a>>1;for(var d=c+b/2;!(c>=d)&&da()[c>>>0];)++c;
c<<=1;if(32<c-a&&Wb)return Wb.decode(v().slice(a,c));c="";for(d=0;!(d>=b/2);++d){var f=ba()[a+2*d>>>1>>>0];if(0==f)break;c+=String.fromCharCode(f)}return c},Yb=(a,b,c)=>{c??=2147483647;if(2>c)return 0;c-=2;var d=b;c=c<2*a.length?c/2:a.length;for(var f=0;f<c;++f){var g=a.charCodeAt(f);ba()[b>>>1>>>0]=g;b+=2}ba()[b>>>1>>>0]=0;return b-d},Zb=a=>2*a.length,$b=(a,b)=>{for(var c=0,d="";!(c>=b/4);){var f=z()[a+4*c>>>2>>>0];if(0==f)break;++c;65536<=f?(f-=65536,d+=String.fromCharCode(55296|f>>10,56320|f&1023)):
d+=String.fromCharCode(f)}return d},ac=(a,b,c)=>{b>>>=0;c??=2147483647;if(4>c)return 0;var d=b;c=d+c-4;for(var f=0;f<a.length;++f){var g=a.charCodeAt(f);if(55296<=g&&57343>=g){var k=a.charCodeAt(++f);g=65536+((g&1023)<<10)|k&1023}z()[b>>>2>>>0]=g;b+=4;if(b+4>c)break}z()[b>>>2>>>0]=0;return b-d},bc=a=>{for(var b=0,c=0;c<a.length;++c){var d=a.charCodeAt(c);55296<=d&&57343>=d&&++c;b+=4}return b},cc=a=>{if(!I)try{if(a(),!(0<O))try{F?nb(J):eb(J)}catch(b){b instanceof Ua||"unwind"==b||sa(1,b)}}catch(b){b instanceof
Ua||"unwind"==b||sa(1,b)}};function dc(a){a>>>=0;"function"===typeof Atomics.nc&&(Atomics.nc(z(),a>>>2,a).value.then(jb),a+=128,Atomics.store(z(),a>>>2,1))}B.__emscripten_thread_mailbox_await=dc;var jb=()=>{var a=ib();a&&(dc(a),cc(ec))};B.checkMailbox=jb;var fc=[],hc=(a,b)=>{var c=Nb[a];if(void 0===c)throw a=gc(a),c=Q(a),X(a),new R(`${b} has unknown type ${c}`);return c},ic=(a,b,c)=>{var d=[];a=a.toWireType(d,c);d.length&&(A()[b>>>2>>>0]=V(d));return a},jc=a=>{try{a()}catch(b){Ma(b)}};
function kc(){var a=Y,b={};for(let [c,d]of Object.entries(a))b[c]="function"==typeof d?(...f)=>{lc.push(c);try{return d(...f)}finally{I||(lc.pop(),C&&1===Z&&0===lc.length&&(Z=0,O+=1,jc(mc),"undefined"!=typeof Fibers&&Fibers.rc()))}}:d;return b}var Z=0,C=null,nc=0,lc=[],oc={},pc={},qc=0,rc=null,sc=[];function oa(){return new Promise((a,b)=>{rc={resolve:a,reject:b}})}
function tc(){var a=uc(65548),b=a+12;A()[a>>>2>>>0]=b;A()[a+4>>>2>>>0]=b+65536;b=lc[0];var c=oc[b];void 0===c&&(c=qc++,oc[b]=c,pc[c]=b);b=c;z()[a+8>>>2>>>0]=b;return a}function vc(){var a=z()[C+8>>>2>>>0];a=Y[pc[a]];--O;return a()}
function wc(a){if(!I){if(0===Z){var b=!1,c=!1;a((d=0)=>{if(!I&&(nc=d,b=!0,c)){Z=2;jc(()=>xc(C));"undefined"!=typeof Browser&&Browser.Hb.Tb&&Browser.Hb.resume();d=!1;try{var f=vc()}catch(l){f=l,d=!0}var g=!1;if(!C){var k=rc;k&&(rc=null,(d?k.reject:k.resolve)(f),g=!0)}if(d&&!g)throw f;}});c=!0;b||(Z=1,C=tc(),"undefined"!=typeof Browser&&Browser.Hb.Tb&&Browser.Hb.pause(),jc(()=>yc(C)))}else 2===Z?(Z=0,jc(zc),X(C),C=null,sc.forEach(cc)):Ma(`invalid state: ${Z}`);return nc}}
function Bc(a){return wc(b=>{a().then(b)})}var Cc=[],Dc={},Ec=a=>{var b=Dc[a];return void 0===b?Q(a):b},Fc=()=>"object"==typeof globalThis?globalThis:Function("return this")(),Gc=a=>{var b=Cc.length;Cc.push(a);return b},Hc=(a,b)=>{for(var c=Array(a),d=0;d<a;++d)c[d]=hc(A()[b+4*d>>>2>>>0],"parameter "+d);return c},Ic=(a,b)=>Object.defineProperty(b,"name",{value:a});
function Jc(a){var b=Function;if(!(b instanceof Function))throw new TypeError(`new_ called with constructor type ${typeof b} which is not a function`);var c=Ic(b.name||"unknownFunctionName",function(){});c.prototype=b.prototype;c=new c;a=b.apply(c,a);return a instanceof Object?a:c}var Kc=a=>0===a%4&&(0!==a%100||0===a%400),Lc=[0,31,60,91,121,152,182,213,244,274,305,335],Mc=[0,31,59,90,120,151,181,212,243,273,304,334];function Nc(a,b,c,d,f,g,k){return F?P(16,1,a,b,c,d,f,g,k):-52}
function Oc(a,b,c,d,f,g){if(F)return P(17,1,a,b,c,d,f,g)}
var Pc=[],Qc=(a,b)=>{Pc.length=0;for(var c;c=v()[a++>>>0];){var d=105!=c;d&=112!=c;b+=d&&b%8?4:0;Pc.push(112==c?A()[b>>>2>>>0]:106==c?K[b>>>3]:105==c?z()[b>>>2>>>0]:ka()[b>>>3>>>0]);b+=d?8:4}return Pc},Rc={},Tc=()=>{if(!Sc){var a={USER:"web_user",LOGNAME:"web_user",PATH:"/",PWD:"/",HOME:"/home/web_user",LANG:("object"==typeof navigator&&navigator.languages&&navigator.languages[0]||"C").replace("-","_")+".UTF-8",_:ra||"./this.program"},b;for(b in Rc)void 0===Rc[b]?delete a[b]:a[b]=Rc[b];var c=[];for(b in a)c.push(`${b}=${a[b]}`);
Sc=c}return Sc},Sc;function Uc(a,b){if(F)return P(18,1,a,b);a>>>=0;b>>>=0;var c=0;Tc().forEach((d,f)=>{var g=b+c;f=A()[a+4*f>>>2>>>0]=g;for(g=0;g<d.length;++g)e()[f++>>>0]=d.charCodeAt(g);e()[f>>>0]=0;c+=d.length+1});return 0}function Vc(a,b){if(F)return P(19,1,a,b);a>>>=0;b>>>=0;var c=Tc();A()[a>>>2>>>0]=c.length;var d=0;c.forEach(f=>d+=f.length+1);A()[b>>>2>>>0]=d;return 0}function Wc(a){return F?P(20,1,a):52}function Xc(a,b,c,d){return F?P(21,1,a,b,c,d):52}
function Yc(a,b,c,d){return F?P(22,1,a,b,c,d):70}var Zc=[null,[],[]];function $c(a,b,c,d){if(F)return P(23,1,a,b,c,d);b>>>=0;c>>>=0;d>>>=0;for(var f=0,g=0;g<c;g++){var k=A()[b>>>2>>>0],l=A()[b+4>>>2>>>0];b+=8;for(var p=0;p<l;p++){var n=v()[k+p>>>0],r=Zc[a];0===n||10===n?((1===a?Ca:H)(ub(r,0)),r.length=0):r.push(n)}f+=l}A()[d>>>2>>>0]=f;return 0}var ad=[31,29,31,30,31,30,31,31,30,31,30,31],bd=[31,28,31,30,31,30,31,31,30,31,30,31];function cd(a){var b=Array(xb(a)+1);yb(a,b,0,b.length);return b}
var dd=(a,b)=>{e().set(a,b>>>0)};
function ed(a,b,c,d){function f(h,u,w){for(h="number"==typeof h?h.toString():h||"";h.length<u;)h=w[0]+h;return h}function g(h,u){return f(h,u,"0")}function k(h,u){function w(Ac){return 0>Ac?-1:0<Ac?1:0}var W;0===(W=w(h.getFullYear()-u.getFullYear()))&&0===(W=w(h.getMonth()-u.getMonth()))&&(W=w(h.getDate()-u.getDate()));return W}function l(h){switch(h.getDay()){case 0:return new Date(h.getFullYear()-1,11,29);case 1:return h;case 2:return new Date(h.getFullYear(),0,3);case 3:return new Date(h.getFullYear(),
0,2);case 4:return new Date(h.getFullYear(),0,1);case 5:return new Date(h.getFullYear()-1,11,31);case 6:return new Date(h.getFullYear()-1,11,30)}}function p(h){var u=h.zb;for(h=new Date((new Date(h.Ab+1900,0,1)).getTime());0<u;){var w=h.getMonth(),W=(Kc(h.getFullYear())?ad:bd)[w];if(u>W-h.getDate())u-=W-h.getDate()+1,h.setDate(1),11>w?h.setMonth(w+1):(h.setMonth(0),h.setFullYear(h.getFullYear()+1));else{h.setDate(h.getDate()+u);break}}w=new Date(h.getFullYear()+1,0,4);u=l(new Date(h.getFullYear(),
0,4));w=l(w);return 0>=k(u,h)?0>=k(w,h)?h.getFullYear()+1:h.getFullYear():h.getFullYear()-1}a>>>=0;b>>>=0;c>>>=0;d>>>=0;var n=A()[d+40>>>2>>>0];d={kc:z()[d>>>2>>>0],jc:z()[d+4>>>2>>>0],Eb:z()[d+8>>>2>>>0],Jb:z()[d+12>>>2>>>0],Fb:z()[d+16>>>2>>>0],Ab:z()[d+20>>>2>>>0],vb:z()[d+24>>>2>>>0],zb:z()[d+28>>>2>>>0],qc:z()[d+32>>>2>>>0],ic:z()[d+36>>>2>>>0],lc:n?M(n):""};c=M(c);n={"%c":"%a %b %d %H:%M:%S %Y","%D":"%m/%d/%y","%F":"%Y-%m-%d","%h":"%b","%r":"%I:%M:%S %p","%R":"%H:%M","%T":"%H:%M:%S","%x":"%m/%d/%y",
"%X":"%H:%M:%S","%Ec":"%c","%EC":"%C","%Ex":"%m/%d/%y","%EX":"%H:%M:%S","%Ey":"%y","%EY":"%Y","%Od":"%d","%Oe":"%e","%OH":"%H","%OI":"%I","%Om":"%m","%OM":"%M","%OS":"%S","%Ou":"%u","%OU":"%U","%OV":"%V","%Ow":"%w","%OW":"%W","%Oy":"%y"};for(var r in n)c=c.replace(new RegExp(r,"g"),n[r]);var x="Sunday Monday Tuesday Wednesday Thursday Friday Saturday".split(" "),y="January February March April May June July August September October November December".split(" ");n={"%a":h=>x[h.vb].substring(0,3),"%A":h=>
x[h.vb],"%b":h=>y[h.Fb].substring(0,3),"%B":h=>y[h.Fb],"%C":h=>g((h.Ab+1900)/100|0,2),"%d":h=>g(h.Jb,2),"%e":h=>f(h.Jb,2," "),"%g":h=>p(h).toString().substring(2),"%G":p,"%H":h=>g(h.Eb,2),"%I":h=>{h=h.Eb;0==h?h=12:12<h&&(h-=12);return g(h,2)},"%j":h=>{for(var u=0,w=0;w<=h.Fb-1;u+=(Kc(h.Ab+1900)?ad:bd)[w++]);return g(h.Jb+u,3)},"%m":h=>g(h.Fb+1,2),"%M":h=>g(h.jc,2),"%n":()=>"\n","%p":h=>0<=h.Eb&&12>h.Eb?"AM":"PM","%S":h=>g(h.kc,2),"%t":()=>"\t","%u":h=>h.vb||7,"%U":h=>g(Math.floor((h.zb+7-h.vb)/7),
2),"%V":h=>{var u=Math.floor((h.zb+7-(h.vb+6)%7)/7);2>=(h.vb+371-h.zb-2)%7&&u++;if(u)53==u&&(w=(h.vb+371-h.zb)%7,4==w||3==w&&Kc(h.Ab)||(u=1));else{u=52;var w=(h.vb+7-h.zb-1)%7;(4==w||5==w&&Kc(h.Ab%400-1))&&u++}return g(u,2)},"%w":h=>h.vb,"%W":h=>g(Math.floor((h.zb+7-(h.vb+6)%7)/7),2),"%y":h=>(h.Ab+1900).toString().substring(2),"%Y":h=>h.Ab+1900,"%z":h=>{h=h.ic;var u=0<=h;h=Math.abs(h)/60;return(u?"+":"-")+String("0000"+(h/60*100+h%60)).slice(-4)},"%Z":h=>h.lc,"%%":()=>"%"};c=c.replace(/%%/g,"\x00\x00");
for(r in n)c.includes(r)&&(c=c.replace(new RegExp(r,"g"),n[r](d)));c=c.replace(/\0\0/g,"%");r=cd(c);if(r.length>b)return 0;dd(r,a);return r.length-1}N.Gb();for(var fd=Array(256),gd=0;256>gd;++gd)fd[gd]=String.fromCharCode(gd);Lb=fd;R=B.BindingError=class extends Error{constructor(a){super(a);this.name="BindingError"}};B.InternalError=class extends Error{constructor(a){super(a);this.name="InternalError"}};T.push(0,1,void 0,1,null,1,!0,1,!1,1);B.count_emval_handles=()=>T.length/2-5-Rb.length;
var hd=[cb,db,rb,vb,wb,Ab,Bb,Cb,Db,Eb,Fb,Gb,Hb,Ib,Jb,Kb,Nc,Oc,Uc,Vc,Wc,Xc,Yc,$c],kd={ua:function(a,b,c){return Bc(async()=>{await B.Zb(a,b,c)})},b:function(a,b,c){a>>>=0;(new ob(a)).Gb(b>>>0,c>>>0);pb=a;qb++;throw pb;},ia:function(a){jd(a>>>0,!D,1,!ta,131072,!1);N.Pb()},G:function(a){a>>>=0;F?postMessage({cmd:"cleanupThread",thread:a}):N.Nb(N.ub[a])},_:sb,A:vb,pa:wb,W:Ab,Y:Bb,qa:Cb,na:Db,fa:Eb,ma:Fb,K:Gb,X:Hb,U:Ib,oa:Jb,V:Kb,E:function(a,b,c){a>>>=0;c>>>=0;b=Q(b>>>0);S(a,{name:b,fromWireType:d=>d,
toWireType:function(d,f){if("bigint"!=typeof f&&"number"!=typeof f)throw null===f?f="null":(d=typeof f,f="object"===d||"array"===d||"function"===d?f.toString():""+f),new TypeError(`Cannot convert "${f}" to ${this.name}`);"number"==typeof f&&(f=BigInt(f));return f},argPackAdvance:8,readValueFromPointer:Qb(b,c,-1==b.indexOf("u")),Bb:null})},O:function(a,b,c,d){a>>>=0;b=Q(b>>>0);S(a,{name:b,fromWireType:function(f){return!!f},toWireType:function(f,g){return g?c:d},argPackAdvance:8,readValueFromPointer:function(f){return this.fromWireType(v()[f>>>
0])},Bb:null})},N:function(a){return S(a>>>0,Ub)},D:function(a,b,c){a>>>=0;c>>>=0;b=Q(b>>>0);S(a,{name:b,fromWireType:d=>d,toWireType:(d,f)=>f,argPackAdvance:8,readValueFromPointer:Vb(b,c),Bb:null})},t:function(a,b,c,d,f){a>>>=0;c>>>=0;b=Q(b>>>0);-1===f&&(f=4294967295);f=l=>l;if(0===d){var g=32-8*c;f=l=>l<<g>>>g}var k=b.includes("unsigned")?function(l,p){return p>>>0}:function(l,p){return p};S(a,{name:b,fromWireType:f,toWireType:k,argPackAdvance:8,readValueFromPointer:Qb(b,c,0!==d),Bb:null})},p:function(a,
b,c){function d(g){var k=A()[g>>>2>>>0];g=A()[g+4>>>2>>>0];return new f(e().buffer,g,k)}a>>>=0;var f=[Int8Array,Uint8Array,Int16Array,Uint16Array,Int32Array,Uint32Array,Float32Array,Float64Array,BigInt64Array,BigUint64Array][b];c=Q(c>>>0);S(a,{name:c,fromWireType:d,argPackAdvance:8,readValueFromPointer:d},{Ub:!0})},F:function(a,b){a>>>=0;b=Q(b>>>0);var c="std::string"===b;S(a,{name:b,fromWireType:function(d){var f=A()[d>>>2>>>0],g=d+4;if(c)for(var k=g,l=0;l<=f;++l){var p=g+l;if(l==f||0==v()[p>>>0]){k=
M(k,p-k);if(void 0===n)var n=k;else n+=String.fromCharCode(0),n+=k;k=p+1}}else{n=Array(f);for(l=0;l<f;++l)n[l]=String.fromCharCode(v()[g+l>>>0]);n=n.join("")}X(d);return n},toWireType:function(d,f){f instanceof ArrayBuffer&&(f=new Uint8Array(f));var g="string"==typeof f;if(!(g||f instanceof Uint8Array||f instanceof Uint8ClampedArray||f instanceof Int8Array))throw new R("Cannot pass non-string to std::string");var k=c&&g?xb(f):f.length;var l=uc(4+k+1),p=l+4;A()[l>>>2>>>0]=k;if(c&&g)zb(f,p,k+1);else if(g)for(g=
0;g<k;++g){var n=f.charCodeAt(g);if(255<n)throw X(p),new R("String has UTF-16 code units that do not fit in 8 bits");v()[p+g>>>0]=n}else for(g=0;g<k;++g)v()[p+g>>>0]=f[g];null!==d&&d.push(X,l);return l},argPackAdvance:8,readValueFromPointer:Tb,Bb(d){X(d)}})},z:function(a,b,c){a>>>=0;b>>>=0;c>>>=0;c=Q(c);if(2===b){var d=Xb;var f=Yb;var g=Zb;var k=l=>da()[l>>>1>>>0]}else 4===b&&(d=$b,f=ac,g=bc,k=l=>A()[l>>>2>>>0]);S(a,{name:c,fromWireType:l=>{for(var p=A()[l>>>2>>>0],n,r=l+4,x=0;x<=p;++x){var y=l+4+
x*b;if(x==p||0==k(y))r=d(r,y-r),void 0===n?n=r:(n+=String.fromCharCode(0),n+=r),r=y+b}X(l);return n},toWireType:(l,p)=>{if("string"!=typeof p)throw new R(`Cannot pass non-string to C++ string type ${c}`);var n=g(p),r=uc(4+n+b);A()[r>>>2>>>0]=n/b;f(p,r+4,n+b);null!==l&&l.push(X,r);return r},argPackAdvance:8,readValueFromPointer:Tb,Bb(l){X(l)}})},P:function(a,b){a>>>=0;b=Q(b>>>0);S(a,{Vb:!0,name:b,argPackAdvance:0,fromWireType:()=>{},toWireType:()=>{}})},ta:()=>1,S:function(a,b){a>>>=0;a==b>>>0?setTimeout(jb):
F?postMessage({targetThread:a,cmd:"checkMailbox"}):(a=N.ub[a])&&a.postMessage({cmd:"checkMailbox"})},$:function(a,b,c,d,f){b>>>=0;c>>>=0;d/=2;fc.length=d;f=f>>>0>>>3;for(var g=0;g<d;g++)fc[g]=K[f+2*g]?K[f+2*g+1]:ka()[f+2*g+1>>>0];a=b?Ta[b]:hd[a];N.Sb=c;c=a(...fc);N.Sb=0;return c},ha:dc,sa:function(a){E&&N.ub[a>>>0].ref()},w:function(a,b,c){b>>>=0;c>>>=0;a=U(a>>>0);b=hc(b,"emval::as");return ic(b,c,a)},y:function(a){a>>>=0;return Bc(()=>{a=U(a);return a.then(V)})},o:function(a,b,c,d){c>>>=0;d>>>=0;
a=Cc[a>>>0];b=U(b>>>0);return a(null,b,c,d)},k:function(a,b,c,d,f){c>>>=0;d>>>=0;f>>>=0;a=Cc[a>>>0];b=U(b>>>0);c=Ec(c);return a(b,b[c],d,f)},c:Sb,C:function(a,b){b>>>=0;a=U(a>>>0);b=U(b);return a==b},n:function(a){a>>>=0;if(0===a)return V(Fc());a=Ec(a);return V(Fc()[a])},j:function(a,b,c){b=Hc(a,b>>>0);var d=b.shift();a--;var f="return function (obj, func, destructorsRef, args) {\n",g=0,k=[];0===c&&k.push("obj");for(var l=["retType"],p=[d],n=0;n<a;++n)k.push("arg"+n),l.push("argType"+n),p.push(b[n]),
f+=`  var arg${n} = argType${n}.readValueFromPointer(args${g?"+"+g:""});\n`,g+=b[n].argPackAdvance;f+=`  var rv = ${1===c?"new func":"func.call"}(${k.join(", ")});\n`;d.Vb||(l.push("emval_returnValue"),p.push(ic),f+="  return emval_returnValue(retType, destructorsRef, rv);\n");l.push(f+"};\n");a=Jc(l)(...p);c=`methodCaller<(${b.map(r=>r.name).join(", ")}) => ${d.name}>`;return Gc(Ic(c,a))},s:function(a,b){b>>>=0;a=U(a>>>0);b=U(b);return V(a[b])},d:function(a){a>>>=0;9<a&&(T[a+1]+=1)},v:function(){return V([])},
m:function(a){a=U(a>>>0);for(var b=Array(a.length),c=0;c<a.length;c++)b[c]=a[c];return V(b)},g:function(a){return V(Ec(a>>>0))},l:function(){return V({})},i:function(a){a>>>=0;for(var b=U(a);b.length;){var c=b.pop();b.pop()(c)}Sb(a)},h:function(a,b,c){b>>>=0;c>>>=0;a=U(a>>>0);b=U(b);c=U(c);a[b]=c},e:function(a,b){b>>>=0;a=hc(a>>>0,"_emval_take_value");a=a.readValueFromPointer(b);return V(a)},ca:function(a,b){a=-9007199254740992>a||9007199254740992<a?NaN:Number(a);b>>>=0;a=new Date(1E3*a);z()[b>>>
2>>>0]=a.getUTCSeconds();z()[b+4>>>2>>>0]=a.getUTCMinutes();z()[b+8>>>2>>>0]=a.getUTCHours();z()[b+12>>>2>>>0]=a.getUTCDate();z()[b+16>>>2>>>0]=a.getUTCMonth();z()[b+20>>>2>>>0]=a.getUTCFullYear()-1900;z()[b+24>>>2>>>0]=a.getUTCDay();a=(a.getTime()-Date.UTC(a.getUTCFullYear(),0,1,0,0,0,0))/864E5|0;z()[b+28>>>2>>>0]=a},da:function(a,b){a=-9007199254740992>a||9007199254740992<a?NaN:Number(a);b>>>=0;a=new Date(1E3*a);z()[b>>>2>>>0]=a.getSeconds();z()[b+4>>>2>>>0]=a.getMinutes();z()[b+8>>>2>>>0]=a.getHours();
z()[b+12>>>2>>>0]=a.getDate();z()[b+16>>>2>>>0]=a.getMonth();z()[b+20>>>2>>>0]=a.getFullYear()-1900;z()[b+24>>>2>>>0]=a.getDay();var c=(Kc(a.getFullYear())?Lc:Mc)[a.getMonth()]+a.getDate()-1|0;z()[b+28>>>2>>>0]=c;z()[b+36>>>2>>>0]=-(60*a.getTimezoneOffset());c=(new Date(a.getFullYear(),6,1)).getTimezoneOffset();var d=(new Date(a.getFullYear(),0,1)).getTimezoneOffset();a=(c!=d&&a.getTimezoneOffset()==Math.min(d,c))|0;z()[b+32>>>2>>>0]=a},ea:function(a){a>>>=0;var b=new Date(z()[a+20>>>2>>>0]+1900,
z()[a+16>>>2>>>0],z()[a+12>>>2>>>0],z()[a+8>>>2>>>0],z()[a+4>>>2>>>0],z()[a>>>2>>>0],0),c=z()[a+32>>>2>>>0],d=b.getTimezoneOffset(),f=(new Date(b.getFullYear(),6,1)).getTimezoneOffset(),g=(new Date(b.getFullYear(),0,1)).getTimezoneOffset(),k=Math.min(g,f);0>c?z()[a+32>>>2>>>0]=Number(f!=g&&k==d):0<c!=(k==d)&&(f=Math.max(g,f),b.setTime(b.getTime()+6E4*((0<c?k:f)-d)));z()[a+24>>>2>>>0]=b.getDay();c=(Kc(b.getFullYear())?Lc:Mc)[b.getMonth()]+b.getDate()-1|0;z()[a+28>>>2>>>0]=c;z()[a>>>2>>>0]=b.getSeconds();
z()[a+4>>>2>>>0]=b.getMinutes();z()[a+8>>>2>>>0]=b.getHours();z()[a+12>>>2>>>0]=b.getDate();z()[a+16>>>2>>>0]=b.getMonth();z()[a+20>>>2>>>0]=b.getYear();a=b.getTime();return BigInt(isNaN(a)?-1:a/1E3)},aa:Nc,ba:Oc,R:function(a,b,c,d){a>>>=0;b>>>=0;c>>>=0;d>>>=0;var f=(new Date).getFullYear(),g=new Date(f,0,1),k=new Date(f,6,1);f=g.getTimezoneOffset();var l=k.getTimezoneOffset(),p=Math.max(f,l);A()[a>>>2>>>0]=60*p;z()[b>>>2>>>0]=Number(f!=l);a=n=>n.toLocaleTimeString(void 0,{hour12:!1,timeZoneName:"short"}).split(" ")[1];
g=a(g);k=a(k);l<f?(zb(g,c,17),zb(k,d,17)):(zb(g,d,17),zb(k,c,17))},q:()=>{Ma("")},f:function(a,b,c){a>>>=0;b=Qc(b>>>0,c>>>0);return Ta[a](...b)},M:function(a,b,c){a>>>=0;b=Qc(b>>>0,c>>>0);return Ta[a](...b)},H:()=>{},L:()=>Date.now(),ra:()=>{O+=1;throw"unwind";},T:function(){return 4294901760},u:()=>performance.timeOrigin+performance.now(),x:()=>E?require("os").cpus().length:navigator.hardwareConcurrency,Q:function(a){a>>>=0;var b=v().length;if(a<=b||4294901760<a)return!1;for(var c=1;4>=c;c*=2){var d=
b*(1+.2/c);d=Math.min(d,a+100663296);var f=Math;d=Math.max(a,d);a:{f=(f.min.call(f,4294901760,d+(65536-d%65536)%65536)-m.buffer.byteLength+65535)/65536;try{m.grow(f);t();var g=1;break a}catch(k){}g=void 0}if(g)return!0}return!1},ja:Uc,la:Vc,Z:eb,B:Wc,J:Xc,ga:Yc,I:$c,a:m||B.wasmMemory,ka:ed,r:function(a,b,c,d){return ed(a>>>0,b>>>0,c>>>0,d>>>0)}},Y=function(){function a(c,d){Y=c.exports;Y=kc();Y=ld();N.Qb.push(Y.cb);Ha.unshift(Y.va);Da=d;La();return Y}var b={a:kd};L++;if(B.instantiateWasm)try{return B.instantiateWasm(b,
a)}catch(c){H(`Module.instantiateWasm callback failed with error: ${c}`),na(c)}Sa(b,function(c){a(c.instance,c.module)}).catch(na);return{}}(),gc=a=>(gc=Y.wa)(a);B.__embind_initialize_bindings=()=>(B.__embind_initialize_bindings=Y.xa)();B._OrtInit=(a,b)=>(B._OrtInit=Y.ya)(a,b);B._OrtGetLastError=(a,b)=>(B._OrtGetLastError=Y.za)(a,b);B._OrtCreateSessionOptions=(a,b,c,d,f,g,k,l,p,n)=>(B._OrtCreateSessionOptions=Y.Aa)(a,b,c,d,f,g,k,l,p,n);
B._OrtAppendExecutionProvider=(a,b)=>(B._OrtAppendExecutionProvider=Y.Ba)(a,b);B._OrtAddFreeDimensionOverride=(a,b,c)=>(B._OrtAddFreeDimensionOverride=Y.Ca)(a,b,c);B._OrtAddSessionConfigEntry=(a,b,c)=>(B._OrtAddSessionConfigEntry=Y.Da)(a,b,c);B._OrtReleaseSessionOptions=a=>(B._OrtReleaseSessionOptions=Y.Ea)(a);B._OrtCreateSession=(a,b,c)=>(B._OrtCreateSession=Y.Fa)(a,b,c);B._OrtReleaseSession=a=>(B._OrtReleaseSession=Y.Ga)(a);
B._OrtGetInputOutputCount=(a,b,c)=>(B._OrtGetInputOutputCount=Y.Ha)(a,b,c);B._OrtGetInputName=(a,b)=>(B._OrtGetInputName=Y.Ia)(a,b);B._OrtGetOutputName=(a,b)=>(B._OrtGetOutputName=Y.Ja)(a,b);B._OrtFree=a=>(B._OrtFree=Y.Ka)(a);B._OrtCreateTensor=(a,b,c,d,f,g)=>(B._OrtCreateTensor=Y.La)(a,b,c,d,f,g);B._OrtGetTensorData=(a,b,c,d,f)=>(B._OrtGetTensorData=Y.Ma)(a,b,c,d,f);B._OrtReleaseTensor=a=>(B._OrtReleaseTensor=Y.Na)(a);B._OrtCreateRunOptions=(a,b,c,d)=>(B._OrtCreateRunOptions=Y.Oa)(a,b,c,d);
B._OrtAddRunConfigEntry=(a,b,c)=>(B._OrtAddRunConfigEntry=Y.Pa)(a,b,c);B._OrtReleaseRunOptions=a=>(B._OrtReleaseRunOptions=Y.Qa)(a);B._OrtCreateBinding=a=>(B._OrtCreateBinding=Y.Ra)(a);B._OrtBindInput=(a,b,c)=>(B._OrtBindInput=Y.Sa)(a,b,c);B._OrtBindOutput=(a,b,c,d)=>(B._OrtBindOutput=Y.Ta)(a,b,c,d);B._OrtClearBoundOutputs=a=>(B._OrtClearBoundOutputs=Y.Ua)(a);B._OrtReleaseBinding=a=>(B._OrtReleaseBinding=Y.Va)(a);B._OrtRunWithBinding=(a,b,c,d,f)=>(B._OrtRunWithBinding=Y.Wa)(a,b,c,d,f);
B._OrtRun=(a,b,c,d,f,g,k,l)=>(B._OrtRun=Y.Xa)(a,b,c,d,f,g,k,l);B._OrtEndProfiling=a=>(B._OrtEndProfiling=Y.Ya)(a);B._JsepOutput=(a,b,c)=>(B._JsepOutput=Y.Za)(a,b,c);B._JsepGetNodeName=a=>(B._JsepGetNodeName=Y._a)(a);var ib=B._pthread_self=()=>(ib=B._pthread_self=Y.$a)(),uc=B._malloc=a=>(uc=B._malloc=Y.ab)(a),X=B._free=a=>(X=B._free=Y.bb)(a);B.__emscripten_tls_init=()=>(B.__emscripten_tls_init=Y.cb)();var jd=B.__emscripten_thread_init=(a,b,c,d,f,g)=>(jd=B.__emscripten_thread_init=Y.eb)(a,b,c,d,f,g);
B.__emscripten_thread_crashed=()=>(B.__emscripten_thread_crashed=Y.fb)();var bb=(a,b,c,d,f)=>(bb=Y.gb)(a,b,c,d,f),hb=a=>(hb=Y.hb)(a),nb=B.__emscripten_thread_exit=a=>(nb=B.__emscripten_thread_exit=Y.ib)(a),ec=()=>(ec=Y.jb)(),lb=(a,b)=>(lb=Y.kb)(a,b),Za=a=>(Za=Y.lb)(a),ab=a=>(ab=Y.mb)(a),Ya=()=>(Ya=Y.nb)(),mb=B.dynCall_ii=(a,b)=>(mb=B.dynCall_ii=Y.pb)(a,b),yc=a=>(yc=Y.qb)(a),mc=()=>(mc=Y.rb)(),xc=a=>(xc=Y.sb)(a),zc=()=>(zc=Y.tb)();B.___start_em_js=839500;B.___stop_em_js=839661;
function ld(){var a=Y;a=Object.assign({},a);var b=d=>f=>d(f)>>>0,c=d=>()=>d()>>>0;a.wa=b(a.wa);a.$a=c(a.$a);a.ab=b(a.ab);a.emscripten_main_runtime_thread_id=c(a.emscripten_main_runtime_thread_id);a.mb=b(a.mb);a.nb=c(a.nb);return a}B.wasmMemory=m;B.stackSave=()=>Ya();B.stackRestore=a=>Za(a);B.stackAlloc=a=>ab(a);B.keepRuntimeAlive=()=>0<O;B.UTF8ToString=M;B.stringToUTF8=zb;B.lengthBytesUTF8=xb;B.ExitStatus=Ua;B.PThread=N;var md;Ka=function nd(){md||od();md||(Ka=nd)};
function od(){if(!(0<L))if(F)ma(B),F||kb(Ha),startWorker(B);else{if(B.preRun)for("function"==typeof B.preRun&&(B.preRun=[B.preRun]);B.preRun.length;)Ga.unshift(B.preRun.shift());kb(Ga);0<L||md||(md=!0,B.calledRun=!0,I||(F||kb(Ha),ma(B),F||kb(Ia)))}}od();


  return readyPromise
}
);
})();
if (typeof exports === 'object' && typeof module === 'object')
  module.exports = ortWasmThreaded;
else if (typeof define === 'function' && define['amd'])
  define([], () => ortWasmThreaded);
