{
  "name": "@imgly/background-removal",
  "version": "1.5.3",
  "description": "Background Removal in the Browser",
  "keywords": [
    "background-removal",
    "client-side",
    "data-privacy",
    "image-segmentation",
    "image-matting",
    "onnx"
  ],
  "repository": {
    "type": "git",
    "url": "git+https://github.com/imgly/background-removal-js.git"
  },
  "license": "SEE LICENSE IN LICENSE.md",
  "author": {
    "name": "IMG.LY GmbH",
    "email": "support@img.ly",
    "url": "https://img.ly"
  },
  "bugs": {
    "email": "support@img.ly"
  },
  "source": "./src/index.ts",
  "main": "./dist/index.cjs",
  "module": "./dist/index.mjs",
  "types": "./dist/src/index.d.ts",
  "exports": {
    ".": {
      "require": "./dist/index.cjs",
      "import": "./dist/index.mjs",
      "types": "./dist/src/index.d.ts"
    }
  },
  "homepage": "https://img.ly/showcases/cesdk/web/background-removal",
  "files": [
    "LICENSE.md",
    "README.md",
    "CHANGELOG.md",
    "ThirdPartyLicenses.json",
    "dist/",
    "bin/"
  ],
  "scripts": {
    "start": "npm run watch",
    "clean": "npx rimraf dist",
    "test": "true",
    "resources": "node ../../scripts/package-resources.mjs",
    "changelog:create": "node ../../scripts/changelog/changelog-create.mjs",
    "changelog:generate": "node ../../scripts/changelog/changelog-generate.mjs",
    "build": "npm run clean && npm run types && npm run resources && npm run changelog:generate && node scripts/build.mjs",
    "types": " npx tsc --declaration --emitDeclarationOnly --declarationDir dist --declarationMap",
    "watch": "npm run clean && npm run resources && npm run changelog:generate && node scripts/watch.mjs",
    "publish:latest": "npm publish --tag latest --access public",
    "publish:next": "npm publish --tag next --access public",
    "package:pack": "npm pack . --pack-destination ../../releases",
    "lint": "npx prettier --write ."
  },
  "dependencies": {
    "@types/lodash-es": "^4.17.12",
    "@types/ndarray": "~1.0.14",
    "@types/node": "~20.3.0",
    "lodash-es": "^4.17.21",
    "ndarray": "~1.0.0",
    "onnxruntime-web": "~1.18.0",
    "zod": "^3.23.8"
  },
  "devDependencies": {
    "assert": "~2.0.0",
    "esbuild": "~0.18.0",
    "glob": "~10.3.0",
    "npm-dts": "~1.3.0",
    "process": "~0.11.0",
    "ts-loader": "~9.4.0",
    "tslib": "~2.5.0",
    "typescript": "~5.1.0",
    "util": "~0.12.0",
    "webpack": "~5.85.0",
    "webpack-cli": "~5.1.0"
  },
  "bundleDependencies": []
}