
var ortWasm = (() => {
  var _scriptDir = typeof document != 'undefined' ? document.currentScript?.src : undefined;
  if (typeof __filename != 'undefined') _scriptDir ||= __filename;
  return (
function(moduleArg = {}) {

var e=moduleArg,aa,m,readyPromise=new Promise((a,b)=>{aa=a;m=b});"use strict";e.mountExternalData=(a,b)=>{(e.eb||(e.eb=new Map)).set(a,b)};e.unmountExternalData=()=>{delete e.eb};
let ca=()=>{const a=(c,d,f)=>(...g)=>{const k=r,l=d?.();g=c(...g);const p=d?.();l!==p&&(c=p,f(l),d=f=null);return r!=k?ba():g},b=c=>async(...d)=>{try{if(e.cb)throw Error("Session already started");const f=e.cb={xb:d[0],errors:[]},g=await c(...d);if(e.cb!==f)throw Error("Session mismatch");e.kb?.flush();const k=f.errors;if(0<k.length){let l=await Promise.all(k);l=l.filter(p=>p);if(0<l.length)throw Error(l.join("\n"));}return g}finally{e.cb=null}};e._OrtCreateSession=a(e._OrtCreateSession,()=>e._OrtCreateSession,
c=>e._OrtCreateSession=c);e._OrtRun=b(a(e._OrtRun,()=>e._OrtRun,c=>e._OrtRun=c));e._OrtRunWithBinding=b(a(e._OrtRunWithBinding,()=>e._OrtRunWithBinding,c=>e._OrtRunWithBinding=c));e._OrtBindInput=a(e._OrtBindInput,()=>e._OrtBindInput,c=>e._OrtBindInput=c);ca=void 0};
e.jsepInit=(a,b)=>{ca?.();if("webgpu"===a){[e.kb,e.pb,e.tb,e.lb,e.sb,e.Ra,e.ub,e.wb,e.qb,e.rb,e.vb]=b;const c=e.kb;e.jsepRegisterBuffer=(d,f,g,k)=>c.registerBuffer(d,f,g,k);e.jsepGetBuffer=d=>c.getBuffer(d);e.jsepCreateDownloader=(d,f,g)=>c.createDownloader(d,f,g);e.jsepOnReleaseSession=d=>{c.onReleaseSession(d)};e.jsepOnRunStart=d=>c.onRunStart(d)}};
var da=Object.assign({},e),ea="./this.program",x=(a,b)=>{throw b;},fa="object"==typeof window,y="function"==typeof importScripts,ha="object"==typeof process&&"object"==typeof process.versions&&"string"==typeof process.versions.node,z="",ia,A,B;
if(ha){var fs=require("fs"),ja=require("path");z=y?ja.dirname(z)+"/":__dirname+"/";ia=(a,b)=>{a=C(a)?new URL(a):ja.normalize(a);return fs.readFileSync(a,b?void 0:"utf8")};B=a=>{a=ia(a,!0);a.buffer||(a=new Uint8Array(a));return a};A=(a,b,c,d=!0)=>{a=C(a)?new URL(a):ja.normalize(a);fs.readFile(a,d?void 0:"utf8",(f,g)=>{f?c(f):b(d?g.buffer:g)})};!e.thisProgram&&1<process.argv.length&&(ea=process.argv[1].replace(/\\/g,"/"));process.argv.slice(2);x=(a,b)=>{process.exitCode=a;throw b;}}else if(fa||y)y?
z=self.location.href:"undefined"!=typeof document&&document.currentScript&&(z=document.currentScript.src),_scriptDir&&(z=_scriptDir),z.startsWith("blob:")?z="":z=z.substr(0,z.replace(/[?#].*/,"").lastIndexOf("/")+1),ia=a=>{var b=new XMLHttpRequest;b.open("GET",a,!1);b.send(null);return b.responseText},y&&(B=a=>{var b=new XMLHttpRequest;b.open("GET",a,!1);b.responseType="arraybuffer";b.send(null);return new Uint8Array(b.response)}),A=(a,b,c)=>{var d=new XMLHttpRequest;d.open("GET",a,!0);d.responseType=
"arraybuffer";d.onload=()=>{200==d.status||0==d.status&&d.response?b(d.response):c()};d.onerror=c;d.send(null)};var ka=console.log.bind(console),D=console.error.bind(console);Object.assign(e,da);da=null;var la,E=!1,ma,F,G,H,na,I,J,oa,pa,qa,ra;
function sa(){var a=la.buffer;e.HEAP8=F=new Int8Array(a);e.HEAP16=H=new Int16Array(a);e.HEAPU8=G=new Uint8Array(a);e.HEAPU16=na=new Uint16Array(a);e.HEAP32=I=new Int32Array(a);e.HEAPU32=J=new Uint32Array(a);e.HEAPF32=oa=new Float32Array(a);e.HEAPF64=ra=new Float64Array(a);e.HEAP64=pa=new BigInt64Array(a);e.HEAPU64=qa=new BigUint64Array(a)}var ta=[],ua=[],va=[],K=0,wa=null,L=null;
function xa(a){a="Aborted("+a+")";D(a);E=!0;ma=1;a=new WebAssembly.RuntimeError(a+". Build with -sASSERTIONS for more info.");m(a);throw a;}var ya=a=>a.startsWith("data:application/octet-stream;base64,"),C=a=>a.startsWith("file://"),M;M="ort-wasm-simd.wasm";if(!ya(M)){var za=M;M=e.locateFile?e.locateFile(za,z):z+za}function Aa(a){if(B)return B(a);throw"both async and sync fetching of the wasm failed";}
function Ba(a){if(fa||y){if("function"==typeof fetch&&!C(a))return fetch(a,{credentials:"same-origin"}).then(b=>{if(!b.ok)throw`failed to load wasm binary file at '${a}'`;return b.arrayBuffer()}).catch(()=>Aa(a));if(A)return new Promise((b,c)=>{A(a,d=>b(new Uint8Array(d)),c)})}return Promise.resolve().then(()=>Aa(a))}function Ca(a,b,c){return Ba(a).then(d=>WebAssembly.instantiate(d,b)).then(c,d=>{D(`failed to asynchronously prepare wasm: ${d}`);xa(d)})}
function Da(a,b){var c=M;return"function"!=typeof WebAssembly.instantiateStreaming||ya(c)||C(c)||ha||"function"!=typeof fetch?Ca(c,a,b):fetch(c,{credentials:"same-origin"}).then(d=>WebAssembly.instantiateStreaming(d,a).then(b,function(f){D(`wasm streaming compile failed: ${f}`);D("falling back to ArrayBuffer instantiation");return Ca(c,a,b)}))}
var Ea={824920:(a,b,c,d)=>{if("undefined"==typeof e||!e.eb)return 1;a=O(a>>>0);a.startsWith("./")&&(a=a.substring(2));a=e.eb.get(a);if(!a)return 2;b>>>=0;c>>>=0;if(b+c>a.byteLength)return 3;try{return G.set(a.subarray(b,b+c),d>>>0>>>0),0}catch{return 4}},825421:()=>{e.qb()},825452:()=>{e.rb()},825481:()=>{e.vb()},825506:a=>e.pb(a),825539:a=>e.tb(a),825571:(a,b,c)=>{e.lb(a,b,c,!0)},825610:(a,b,c)=>{e.lb(a,b,c)},825643:a=>{e.Ra("Abs",a,void 0)},825694:a=>{e.Ra("Neg",a,void 0)},825745:a=>{e.Ra("Floor",
a,void 0)},825798:a=>{e.Ra("Ceil",a,void 0)},825850:a=>{e.Ra("Reciprocal",a,void 0)},825908:a=>{e.Ra("Sqrt",a,void 0)},825960:a=>{e.Ra("Exp",a,void 0)},826011:a=>{e.Ra("Erf",a,void 0)},826062:a=>{e.Ra("Sigmoid",a,void 0)},826117:(a,b,c)=>{e.Ra("HardSigmoid",a,{alpha:b,beta:c})},826196:a=>{e.Ra("Log",a,void 0)},826247:a=>{e.Ra("Sin",a,void 0)},826298:a=>{e.Ra("Cos",a,void 0)},826349:a=>{e.Ra("Tan",a,void 0)},826400:a=>{e.Ra("Asin",a,void 0)},826452:a=>{e.Ra("Acos",a,void 0)},826504:a=>{e.Ra("Atan",
a,void 0)},826556:a=>{e.Ra("Sinh",a,void 0)},826608:a=>{e.Ra("Cosh",a,void 0)},826660:a=>{e.Ra("Asinh",a,void 0)},826713:a=>{e.Ra("Acosh",a,void 0)},826766:a=>{e.Ra("Atanh",a,void 0)},826819:a=>{e.Ra("Tanh",a,void 0)},826871:a=>{e.Ra("Not",a,void 0)},826922:(a,b,c)=>{e.Ra("Clip",a,{min:b,max:c})},826991:a=>{e.Ra("Clip",a,void 0)},827043:(a,b)=>{e.Ra("Elu",a,{alpha:b})},827101:a=>{e.Ra("Relu",a,void 0)},827153:(a,b)=>{e.Ra("LeakyRelu",a,{alpha:b})},827217:(a,b)=>{e.Ra("ThresholdedRelu",a,{alpha:b})},
827287:(a,b)=>{e.Ra("Cast",a,{to:b})},827345:a=>{e.Ra("Add",a,void 0)},827396:a=>{e.Ra("Sub",a,void 0)},827447:a=>{e.Ra("Mul",a,void 0)},827498:a=>{e.Ra("Div",a,void 0)},827549:a=>{e.Ra("Pow",a,void 0)},827600:a=>{e.Ra("Equal",a,void 0)},827653:a=>{e.Ra("Greater",a,void 0)},827708:a=>{e.Ra("GreaterOrEqual",a,void 0)},827770:a=>{e.Ra("Less",a,void 0)},827822:a=>{e.Ra("LessOrEqual",a,void 0)},827881:(a,b,c,d,f)=>{e.Ra("ReduceMean",a,{keepDims:!!b,noopWithEmptyAxes:!!c,axes:d?Array.from(I.subarray(d>>>
0,f>>>0)):[]})},828040:(a,b,c,d,f)=>{e.Ra("ReduceMax",a,{keepDims:!!b,noopWithEmptyAxes:!!c,axes:d?Array.from(I.subarray(d>>>0,f>>>0)):[]})},828198:(a,b,c,d,f)=>{e.Ra("ReduceMin",a,{keepDims:!!b,noopWithEmptyAxes:!!c,axes:d?Array.from(I.subarray(d>>>0,f>>>0)):[]})},828356:(a,b,c,d,f)=>{e.Ra("ReduceProd",a,{keepDims:!!b,noopWithEmptyAxes:!!c,axes:d?Array.from(I.subarray(d>>>0,f>>>0)):[]})},828515:(a,b,c,d,f)=>{e.Ra("ReduceSum",a,{keepDims:!!b,noopWithEmptyAxes:!!c,axes:d?Array.from(I.subarray(d>>>
0,f>>>0)):[]})},828673:(a,b,c,d,f)=>{e.Ra("ReduceL1",a,{keepDims:!!b,noopWithEmptyAxes:!!c,axes:d?Array.from(I.subarray(d>>>0,f>>>0)):[]})},828830:(a,b,c,d,f)=>{e.Ra("ReduceL2",a,{keepDims:!!b,noopWithEmptyAxes:!!c,axes:d?Array.from(I.subarray(d>>>0,f>>>0)):[]})},828987:(a,b,c,d,f)=>{e.Ra("ReduceLogSum",a,{keepDims:!!b,noopWithEmptyAxes:!!c,axes:d?Array.from(I.subarray(d>>>0,f>>>0)):[]})},829148:(a,b,c,d,f)=>{e.Ra("ReduceSumSquare",a,{keepDims:!!b,noopWithEmptyAxes:!!c,axes:d?Array.from(I.subarray(d>>>
0,f>>>0)):[]})},829312:(a,b,c,d,f)=>{e.Ra("ReduceLogSumExp",a,{keepDims:!!b,noopWithEmptyAxes:!!c,axes:d?Array.from(I.subarray(d>>>0,f>>>0)):[]})},829476:a=>{e.Ra("Where",a,void 0)},829529:(a,b,c)=>{e.Ra("Transpose",a,{perm:b?Array.from(I.subarray(b>>>0,c>>>0)):[]})},829637:(a,b,c,d)=>{e.Ra("DepthToSpace",a,{blocksize:b,mode:O(c),format:d?"NHWC":"NCHW"})},829770:(a,b,c,d)=>{e.Ra("DepthToSpace",a,{blocksize:b,mode:O(c),format:d?"NHWC":"NCHW"})},829903:(a,b,c,d,f,g,k,l,p,n,q,v,w,h,t)=>{e.Ra("ConvTranspose",
a,{format:p?"NHWC":"NCHW",autoPad:b,dilations:[c],group:d,kernelShape:[f],pads:[g,k],strides:[l],wIsConst:()=>!!F[n>>>0],outputPadding:q?Array.from(I.subarray(q>>>0,v>>>0)):[],outputShape:w?Array.from(I.subarray(w>>>0,h>>>0)):[],activation:O(t)})},830304:(a,b,c,d,f,g,k,l,p,n,q,v,w,h)=>{e.Ra("ConvTranspose",a,{format:l?"NHWC":"NCHW",autoPad:b,dilations:Array.from(I.subarray(c>>>0,(c>>>0)+2>>>0)),group:d,kernelShape:Array.from(I.subarray(f>>>0,(f>>>0)+2>>>0)),pads:Array.from(I.subarray(g>>>0,(g>>>0)+
4>>>0)),strides:Array.from(I.subarray(k>>>0,(k>>>0)+2>>>0)),wIsConst:()=>!!F[p>>>0],outputPadding:n?Array.from(I.subarray(n>>>0,q>>>0)):[],outputShape:v?Array.from(I.subarray(v>>>0,w>>>0)):[],activation:O(h)})},830869:(a,b,c,d,f,g,k,l,p,n,q,v,w,h,t)=>{e.Ra("ConvTranspose",a,{format:p?"NHWC":"NCHW",autoPad:b,dilations:[c],group:d,kernelShape:[f],pads:[g,k],strides:[l],wIsConst:()=>!!F[n>>>0],outputPadding:q?Array.from(I.subarray(q>>>0,v>>>0)):[],outputShape:w?Array.from(I.subarray(w>>>0,h>>>0)):[],
activation:O(t)})},831270:(a,b,c,d,f,g,k,l,p,n,q,v,w,h)=>{e.Ra("ConvTranspose",a,{format:l?"NHWC":"NCHW",autoPad:b,dilations:Array.from(I.subarray(c>>>0,(c>>>0)+2>>>0)),group:d,kernelShape:Array.from(I.subarray(f>>>0,(f>>>0)+2>>>0)),pads:Array.from(I.subarray(g>>>0,(g>>>0)+4>>>0)),strides:Array.from(I.subarray(k>>>0,(k>>>0)+2>>>0)),wIsConst:()=>!!F[p>>>0],outputPadding:n?Array.from(I.subarray(n>>>0,q>>>0)):[],outputShape:v?Array.from(I.subarray(v>>>0,w>>>0)):[],activation:O(h)})},831835:(a,b)=>{e.Ra("GlobalAveragePool",
a,{format:b?"NHWC":"NCHW"})},831926:(a,b,c,d,f,g,k,l,p,n,q,v,w,h,t,u)=>{e.Ra("AveragePool",a,{format:u?"NHWC":"NCHW",auto_pad:b,ceil_mode:c,count_include_pad:d,storage_order:f,dilations:[g,k],kernel_shape:[l,p],pads:[n,q,v,w],strides:[h,t]})},832210:(a,b)=>{e.Ra("GlobalAveragePool",a,{format:b?"NHWC":"NCHW"})},832301:(a,b,c,d,f,g,k,l,p,n,q,v,w,h,t,u)=>{e.Ra("AveragePool",a,{format:u?"NHWC":"NCHW",auto_pad:b,ceil_mode:c,count_include_pad:d,storage_order:f,dilations:[g,k],kernel_shape:[l,p],pads:[n,
q,v,w],strides:[h,t]})},832585:(a,b)=>{e.Ra("GlobalMaxPool",a,{format:b?"NHWC":"NCHW"})},832672:(a,b,c,d,f,g,k,l,p,n,q,v,w,h,t,u)=>{e.Ra("MaxPool",a,{format:u?"NHWC":"NCHW",auto_pad:b,ceil_mode:c,count_include_pad:d,storage_order:f,dilations:[g,k],kernel_shape:[l,p],pads:[n,q,v,w],strides:[h,t]})},832952:(a,b)=>{e.Ra("GlobalMaxPool",a,{format:b?"NHWC":"NCHW"})},833039:(a,b,c,d,f,g,k,l,p,n,q,v,w,h,t,u)=>{e.Ra("MaxPool",a,{format:u?"NHWC":"NCHW",auto_pad:b,ceil_mode:c,count_include_pad:d,storage_order:f,
dilations:[g,k],kernel_shape:[l,p],pads:[n,q,v,w],strides:[h,t]})},833319:(a,b,c,d,f)=>{e.Ra("Gemm",a,{alpha:b,beta:c,transA:d,transB:f})},833423:a=>{e.Ra("MatMul",a,void 0)},833477:(a,b,c,d)=>{e.Ra("ArgMax",a,{keepDims:!!b,selectLastIndex:!!c,axis:d})},833585:(a,b,c,d)=>{e.Ra("ArgMin",a,{keepDims:!!b,selectLastIndex:!!c,axis:d})},833693:(a,b)=>{e.Ra("Softmax",a,{axis:b})},833756:(a,b)=>{e.Ra("Concat",a,{axis:b})},833816:(a,b,c,d,f)=>{e.Ra("Split",a,{axis:b,numOutputs:c,splitSizes:d?Array.from(I.subarray(d>>>
0,f>>>0)):[]})},833956:a=>{e.Ra("Expand",a,void 0)},834010:(a,b)=>{e.Ra("Gather",a,{axis:Number(b)})},834081:(a,b)=>{e.Ra("GatherElements",a,{axis:Number(b)})},834160:(a,b,c,d,f,g,k,l,p,n,q)=>{e.Ra("Resize",a,{antialias:b,axes:c?Array.from(I.subarray(c>>>0,d>>>0)):[],coordinateTransformMode:O(f),cubicCoeffA:g,excludeOutside:k,extrapolationValue:l,keepAspectRatioPolicy:O(p),mode:O(n),nearestMode:O(q)})},834506:(a,b,c,d,f,g,k)=>{e.Ra("Slice",a,{starts:b?Array.from(I.subarray(b>>>0,c>>>0)):[],ends:d?
Array.from(I.subarray(d>>>0,f>>>0)):[],axes:g?Array.from(I.subarray(g>>>0,k>>>0)):[]})},834722:a=>{e.Ra("Tile",a,void 0)},834774:(a,b,c,d)=>{e.Ra("LayerNormalization",a,{axis:b,epsilon:c,simplified:!!d})},834885:(a,b,c)=>{e.Ra("InstanceNormalization",a,{epsilon:b,format:c?"NHWC":"NCHW"})},834999:(a,b,c)=>{e.Ra("InstanceNormalization",a,{epsilon:b,format:c?"NHWC":"NCHW"})},835113:a=>{e.Ra("Range",a,void 0)},835166:(a,b)=>{e.Ra("Einsum",a,{equation:O(b)})},835247:(a,b,c,d,f)=>{e.Ra("Pad",a,{mode:b,
value:c,pads:d?Array.from(I.subarray(d>>>0,f>>>0)):[]})},835374:(a,b,c,d,f,g)=>{e.Ra("BatchNormalization",a,{epsilon:b,momentum:c,spatial:!!f,trainingMode:!!d,format:g?"NHWC":"NCHW"})},835543:(a,b,c,d,f,g)=>{e.Ra("BatchNormalization",a,{epsilon:b,momentum:c,spatial:!!f,trainingMode:!!d,format:g?"NHWC":"NCHW"})},835712:(a,b,c)=>{e.Ra("CumSum",a,{exclusive:Number(b),reverse:Number(c)})},835809:(a,b,c,d,f,g,k,l,p)=>{e.Ra("Attention",a,{numHeads:b,isUnidirectional:c,maskFilterValue:d,scale:f,doRotary:g,
qkvHiddenSizes:k?Array.from(I.subarray(Number(l)>>>0,Number(l)+k>>>0)):[],pastPresentShareBuffer:!!p})},836081:a=>{e.Ra("BiasAdd",a,void 0)},836136:a=>{e.Ra("BiasSplitGelu",a,void 0)},836197:a=>{e.Ra("FastGelu",a,void 0)},836253:(a,b,c,d,f,g,k,l,p,n,q,v,w)=>{e.Ra("Conv",a,{format:p?"NHWC":"NCHW",auto_pad:b,dilations:[c],group:d,kernel_shape:[f],pads:g?Array.from(I.subarray(g>>>0,k>>>0)):[],strides:[l],w_is_const:()=>!!F[n>>>0],activation:O(q),activation_params:v?Array.from(oa.subarray(v>>>0,w>>>0)):
[]})},836623:(a,b,c,d,f,g,k,l,p,n,q,v,w,h,t,u)=>{e.Ra("Conv",a,{format:v?"NHWC":"NCHW",auto_pad:b,dilations:[c,d],group:f,kernel_shape:[g,k],pads:l?Array.from(I.subarray(l>>>0,p>>>0)):[],strides:[n,q],w_is_const:()=>!!F[w>>>0],activation:O(h),activation_params:t?Array.from(oa.subarray(t>>>0,u>>>0)):[]})},837014:a=>{e.Ra("Gelu",a,void 0)},837066:(a,b,c,d,f,g)=>{e.Ra("MatMulNBits",a,{k:b,n:c,accuracyLevel:d,bits:f,blockSize:g})},837193:(a,b,c,d,f,g)=>{e.Ra("MultiHeadAttention",a,{numHeads:b,isUnidirectional:c,
maskFilterValue:d,scale:f,doRotary:g})},837352:(a,b,c,d,f)=>{e.Ra("RotaryEmbedding",a,{interleaved:!!b,numHeads:c,rotaryEmbeddingDim:d,scale:f})},837491:(a,b,c)=>{e.Ra("SkipLayerNormalization",a,{epsilon:b,simplified:!!c})},837593:(a,b,c)=>{e.Ra("SkipLayerNormalization",a,{epsilon:b,simplified:!!c})},837695:(a,b,c,d)=>{e.Ra("LayerNormalization",a,{axis:b,epsilon:c,simplified:!!d})},837806:a=>{e.ub(a)},837840:(a,b)=>e.wb(a,b,e.cb.xb,e.cb.errors)};
function Fa(a){this.name="ExitStatus";this.message=`Program terminated with exit(${a})`;this.status=a}class Ga{constructor(a){this.hb=a-24}}
var Ha=0,Ia=0,Ja="undefined"!=typeof TextDecoder?new TextDecoder("utf8"):void 0,Ka=(a,b,c)=>{b>>>=0;var d=b+c;for(c=b;a[c]&&!(c>=d);)++c;if(16<c-b&&a.buffer&&Ja)return Ja.decode(a.subarray(b,c));for(d="";b<c;){var f=a[b++];if(f&128){var g=a[b++]&63;if(192==(f&224))d+=String.fromCharCode((f&31)<<6|g);else{var k=a[b++]&63;f=224==(f&240)?(f&15)<<12|g<<6|k:(f&7)<<18|g<<12|k<<6|a[b++]&63;65536>f?d+=String.fromCharCode(f):(f-=65536,d+=String.fromCharCode(55296|f>>10,56320|f&1023))}}else d+=String.fromCharCode(f)}return d},
O=(a,b)=>(a>>>=0)?Ka(G,a,b):"",La=a=>{for(var b=0,c=0;c<a.length;++c){var d=a.charCodeAt(c);127>=d?b++:2047>=d?b+=2:55296<=d&&57343>=d?(b+=4,++c):b+=3}return b},P=(a,b,c,d)=>{c>>>=0;if(!(0<d))return 0;var f=c;d=c+d-1;for(var g=0;g<a.length;++g){var k=a.charCodeAt(g);if(55296<=k&&57343>=k){var l=a.charCodeAt(++g);k=65536+((k&1023)<<10)|l&1023}if(127>=k){if(c>=d)break;b[c++>>>0]=k}else{if(2047>=k){if(c+1>=d)break;b[c++>>>0]=192|k>>6}else{if(65535>=k){if(c+2>=d)break;b[c++>>>0]=224|k>>12}else{if(c+3>=
d)break;b[c++>>>0]=240|k>>18;b[c++>>>0]=128|k>>12&63}b[c++>>>0]=128|k>>6&63}b[c++>>>0]=128|k&63}}b[c>>>0]=0;return c-f},Ma,Q=a=>{for(var b="";G[a>>>0];)b+=Ma[G[a++>>>0]];return b},Na={},Oa={},Pa={},R;function Qa(a,b,c={}){var d=b.name;if(!a)throw new R(`type "${d}" must have a positive integer typeid pointer`);if(Oa.hasOwnProperty(a)){if(c.nb)return;throw new R(`Cannot register type '${d}' twice`);}Oa[a]=b;delete Pa[a];Na.hasOwnProperty(a)&&(b=Na[a],delete Na[a],b.forEach(f=>f()))}
function S(a,b,c={}){if(!("argPackAdvance"in b))throw new TypeError("registerType registeredInstance requires argPackAdvance");return Qa(a,b,c)}var Ra=(a,b,c)=>{switch(b){case 1:return c?d=>F[d>>>0]:d=>G[d>>>0];case 2:return c?d=>H[d>>>1>>>0]:d=>na[d>>>1>>>0];case 4:return c?d=>I[d>>>2>>>0]:d=>J[d>>>2>>>0];case 8:return c?d=>pa[d>>>3]:d=>qa[d>>>3];default:throw new TypeError(`invalid integer width (${b}): ${a}`);}},Sa=[],T=[];function Ta(a){a>>>=0;9<a&&0===--T[a+1]&&(T[a]=void 0,Sa.push(a))}
var U=a=>{if(!a)throw new R("Cannot use deleted val. handle = "+a);return T[a]},V=a=>{switch(a){case void 0:return 2;case null:return 4;case !0:return 6;case !1:return 8;default:const b=Sa.pop()||T.length;T[b]=a;T[b+1]=1;return b}};function Ua(a){return this.fromWireType(J[a>>>2>>>0])}
var Va={name:"emscripten::val",fromWireType:a=>{var b=U(a);Ta(a);return b},toWireType:(a,b)=>V(b),argPackAdvance:8,readValueFromPointer:Ua,bb:null},Wa=(a,b)=>{switch(b){case 4:return function(c){return this.fromWireType(oa[c>>>2>>>0])};case 8:return function(c){return this.fromWireType(ra[c>>>3>>>0])};default:throw new TypeError(`invalid float width (${b}): ${a}`);}},Xa="undefined"!=typeof TextDecoder?new TextDecoder("utf-16le"):void 0,Ya=(a,b)=>{var c=a>>1;for(var d=c+b/2;!(c>=d)&&na[c>>>0];)++c;
c<<=1;if(32<c-a&&Xa)return Xa.decode(G.subarray(a>>>0,c>>>0));c="";for(d=0;!(d>=b/2);++d){var f=H[a+2*d>>>1>>>0];if(0==f)break;c+=String.fromCharCode(f)}return c},Za=(a,b,c)=>{c??=2147483647;if(2>c)return 0;c-=2;var d=b;c=c<2*a.length?c/2:a.length;for(var f=0;f<c;++f)H[b>>>1>>>0]=a.charCodeAt(f),b+=2;H[b>>>1>>>0]=0;return b-d},$a=a=>2*a.length,ab=(a,b)=>{for(var c=0,d="";!(c>=b/4);){var f=I[a+4*c>>>2>>>0];if(0==f)break;++c;65536<=f?(f-=65536,d+=String.fromCharCode(55296|f>>10,56320|f&1023)):d+=String.fromCharCode(f)}return d},
bb=(a,b,c)=>{b>>>=0;c??=2147483647;if(4>c)return 0;var d=b;c=d+c-4;for(var f=0;f<a.length;++f){var g=a.charCodeAt(f);if(55296<=g&&57343>=g){var k=a.charCodeAt(++f);g=65536+((g&1023)<<10)|k&1023}I[b>>>2>>>0]=g;b+=4;if(b+4>c)break}I[b>>>2>>>0]=0;return b-d},cb=a=>{for(var b=0,c=0;c<a.length;++c){var d=a.charCodeAt(c);55296<=d&&57343>=d&&++c;b+=4}return b},eb=(a,b)=>{var c=Oa[a];if(void 0===c)throw a=db(a),c=Q(a),W(a),new R(`${b} has unknown type ${c}`);return c},fb=(a,b,c)=>{var d=[];a=a.toWireType(d,
c);d.length&&(J[b>>>2>>>0]=V(d));return a},gb=a=>{try{a()}catch(b){xa(b)}},hb=a=>{if(!E)try{a();try{ma=ma=a=ma,e.onExit?.(a),E=!0,x(a,new Fa(a))}catch(b){b instanceof Fa||"unwind"==b||x(1,b)}}catch(b){b instanceof Fa||"unwind"==b||x(1,b)}};function ib(){var a=X,b={};for(let [c,d]of Object.entries(a))b[c]="function"==typeof d?(...f)=>{jb.push(c);try{return d(...f)}finally{E||(jb.pop(),r&&1===Y&&0===jb.length&&(Y=0,gb(kb),"undefined"!=typeof Fibers&&Fibers.Db()))}}:d;return b}
var Y=0,r=null,lb=0,jb=[],mb={},nb={},ob=0,pb=null,qb=[];function ba(){return new Promise((a,b)=>{pb={resolve:a,reject:b}})}function rb(){var a=tb(65548),b=a+12;J[a>>>2>>>0]=b;J[a+4>>>2>>>0]=b+65536;b=jb[0];var c=mb[b];void 0===c&&(c=ob++,mb[b]=c,nb[c]=b);I[a+8>>>2>>>0]=c;return a}
function ub(a){if(!E){if(0===Y){var b=!1,c=!1;a((d=0)=>{if(!E&&(lb=d,b=!0,c)){Y=2;gb(()=>vb(r));"undefined"!=typeof Browser&&Browser.ib.mb&&Browser.ib.resume();d=!1;try{var f=(0,X[nb[I[r+8>>>2>>>0]]])()}catch(l){f=l,d=!0}var g=!1;if(!r){var k=pb;k&&(pb=null,(d?k.reject:k.resolve)(f),g=!0)}if(d&&!g)throw f;}});c=!0;b||(Y=1,r=rb(),"undefined"!=typeof Browser&&Browser.ib.mb&&Browser.ib.pause(),gb(()=>wb(r)))}else 2===Y?(Y=0,gb(xb),W(r),r=null,qb.forEach(hb)):xa(`invalid state: ${Y}`);return lb}}
function yb(a){return ub(b=>{a().then(b)})}var zb=[],Ab={},Bb=a=>{var b=Ab[a];return void 0===b?Q(a):b},Cb=()=>"object"==typeof globalThis?globalThis:Function("return this")(),Db=a=>{var b=zb.length;zb.push(a);return b},Eb=(a,b)=>{for(var c=Array(a),d=0;d<a;++d)c[d]=eb(J[b+4*d>>>2>>>0],"parameter "+d);return c},Fb=(a,b)=>Object.defineProperty(b,"name",{value:a});
function Gb(a){var b=Function;if(!(b instanceof Function))throw new TypeError(`new_ called with constructor type ${typeof b} which is not a function`);var c=Fb(b.name||"unknownFunctionName",function(){});c.prototype=b.prototype;c=new c;a=b.apply(c,a);return a instanceof Object?a:c}
var Z=a=>0===a%4&&(0!==a%100||0===a%400),Hb=[0,31,60,91,121,152,182,213,244,274,305,335],Ib=[0,31,59,90,120,151,181,212,243,273,304,334],Jb=[],Kb=(a,b)=>{Jb.length=0;for(var c;c=G[a++>>>0];){var d=105!=c;d&=112!=c;b+=d&&b%8?4:0;Jb.push(112==c?J[b>>>2>>>0]:106==c?pa[b>>>3]:105==c?I[b>>>2>>>0]:ra[b>>>3>>>0]);b+=d?8:4}return Jb},Lb={},Nb=()=>{if(!Mb){var a={USER:"web_user",LOGNAME:"web_user",PATH:"/",PWD:"/",HOME:"/home/web_user",LANG:("object"==typeof navigator&&navigator.languages&&navigator.languages[0]||
"C").replace("-","_")+".UTF-8",_:ea||"./this.program"},b;for(b in Lb)void 0===Lb[b]?delete a[b]:a[b]=Lb[b];var c=[];for(b in a)c.push(`${b}=${a[b]}`);Mb=c}return Mb},Mb,Ob=[null,[],[]],Pb=[31,29,31,30,31,30,31,31,30,31,30,31],Qb=[31,28,31,30,31,30,31,31,30,31,30,31];function Rb(a){var b=Array(La(a)+1);P(a,b,0,b.length);return b}
function Sb(a,b,c,d){function f(h,t,u){for(h="number"==typeof h?h.toString():h||"";h.length<t;)h=u[0]+h;return h}function g(h,t){return f(h,t,"0")}function k(h,t){function u(sb){return 0>sb?-1:0<sb?1:0}var N;0===(N=u(h.getFullYear()-t.getFullYear()))&&0===(N=u(h.getMonth()-t.getMonth()))&&(N=u(h.getDate()-t.getDate()));return N}function l(h){switch(h.getDay()){case 0:return new Date(h.getFullYear()-1,11,29);case 1:return h;case 2:return new Date(h.getFullYear(),0,3);case 3:return new Date(h.getFullYear(),
0,2);case 4:return new Date(h.getFullYear(),0,1);case 5:return new Date(h.getFullYear()-1,11,31);case 6:return new Date(h.getFullYear()-1,11,30)}}function p(h){var t=h.$a;for(h=new Date((new Date(h.ab+1900,0,1)).getTime());0<t;){var u=h.getMonth(),N=(Z(h.getFullYear())?Pb:Qb)[u];if(t>N-h.getDate())t-=N-h.getDate()+1,h.setDate(1),11>u?h.setMonth(u+1):(h.setMonth(0),h.setFullYear(h.getFullYear()+1));else{h.setDate(h.getDate()+t);break}}u=new Date(h.getFullYear()+1,0,4);t=l(new Date(h.getFullYear(),
0,4));u=l(u);return 0>=k(t,h)?0>=k(u,h)?h.getFullYear()+1:h.getFullYear():h.getFullYear()-1}a>>>=0;b>>>=0;c>>>=0;d>>>=0;var n=J[d+40>>>2>>>0];d={Ab:I[d>>>2>>>0],zb:I[d+4>>>2>>>0],fb:I[d+8>>>2>>>0],jb:I[d+12>>>2>>>0],gb:I[d+16>>>2>>>0],ab:I[d+20>>>2>>>0],Va:I[d+24>>>2>>>0],$a:I[d+28>>>2>>>0],Cb:I[d+32>>>2>>>0],yb:I[d+36>>>2>>>0],Bb:n?O(n):""};c=O(c);n={"%c":"%a %b %d %H:%M:%S %Y","%D":"%m/%d/%y","%F":"%Y-%m-%d","%h":"%b","%r":"%I:%M:%S %p","%R":"%H:%M","%T":"%H:%M:%S","%x":"%m/%d/%y","%X":"%H:%M:%S",
"%Ec":"%c","%EC":"%C","%Ex":"%m/%d/%y","%EX":"%H:%M:%S","%Ey":"%y","%EY":"%Y","%Od":"%d","%Oe":"%e","%OH":"%H","%OI":"%I","%Om":"%m","%OM":"%M","%OS":"%S","%Ou":"%u","%OU":"%U","%OV":"%V","%Ow":"%w","%OW":"%W","%Oy":"%y"};for(var q in n)c=c.replace(new RegExp(q,"g"),n[q]);var v="Sunday Monday Tuesday Wednesday Thursday Friday Saturday".split(" "),w="January February March April May June July August September October November December".split(" ");n={"%a":h=>v[h.Va].substring(0,3),"%A":h=>v[h.Va],"%b":h=>
w[h.gb].substring(0,3),"%B":h=>w[h.gb],"%C":h=>g((h.ab+1900)/100|0,2),"%d":h=>g(h.jb,2),"%e":h=>f(h.jb,2," "),"%g":h=>p(h).toString().substring(2),"%G":p,"%H":h=>g(h.fb,2),"%I":h=>{h=h.fb;0==h?h=12:12<h&&(h-=12);return g(h,2)},"%j":h=>{for(var t=0,u=0;u<=h.gb-1;t+=(Z(h.ab+1900)?Pb:Qb)[u++]);return g(h.jb+t,3)},"%m":h=>g(h.gb+1,2),"%M":h=>g(h.zb,2),"%n":()=>"\n","%p":h=>0<=h.fb&&12>h.fb?"AM":"PM","%S":h=>g(h.Ab,2),"%t":()=>"\t","%u":h=>h.Va||7,"%U":h=>g(Math.floor((h.$a+7-h.Va)/7),2),"%V":h=>{var t=
Math.floor((h.$a+7-(h.Va+6)%7)/7);2>=(h.Va+371-h.$a-2)%7&&t++;if(t)53==t&&(u=(h.Va+371-h.$a)%7,4==u||3==u&&Z(h.ab)||(t=1));else{t=52;var u=(h.Va+7-h.$a-1)%7;(4==u||5==u&&Z(h.ab%400-1))&&t++}return g(t,2)},"%w":h=>h.Va,"%W":h=>g(Math.floor((h.$a+7-(h.Va+6)%7)/7),2),"%y":h=>(h.ab+1900).toString().substring(2),"%Y":h=>h.ab+1900,"%z":h=>{h=h.yb;var t=0<=h;h=Math.abs(h)/60;return(t?"+":"-")+String("0000"+(h/60*100+h%60)).slice(-4)},"%Z":h=>h.Bb,"%%":()=>"%"};c=c.replace(/%%/g,"\x00\x00");for(q in n)c.includes(q)&&
(c=c.replace(new RegExp(q,"g"),n[q](d)));c=c.replace(/\0\0/g,"%");q=Rb(c);if(q.length>b)return 0;F.set(q,a>>>0);return q.length-1}for(var Tb=Array(256),Ub=0;256>Ub;++Ub)Tb[Ub]=String.fromCharCode(Ub);Ma=Tb;R=e.BindingError=class extends Error{constructor(a){super(a);this.name="BindingError"}};e.InternalError=class extends Error{constructor(a){super(a);this.name="InternalError"}};T.push(0,1,void 0,1,null,1,!0,1,!1,1);e.count_emval_handles=()=>T.length/2-5-Sa.length;
var Vb={ia:function(a,b,c){return yb(async()=>{await e.sb(a,b,c)})},a:function(a,b,c){a>>>=0;var d=new Ga(a);J[d.hb+16>>>2>>>0]=0;J[d.hb+4>>>2>>>0]=b>>>0;J[d.hb+8>>>2>>>0]=c>>>0;Ha=a;Ia++;throw Ha;},y:function(){return 0},ea:function(){},R:function(){},T:function(){},ga:function(){return 0},ca:function(){},Z:function(){},ba:function(){},G:function(){},S:function(){},P:function(){},da:function(){},Q:function(){},C:function(a,b,c){b=Q(b>>>0);S(a>>>0,{name:b,fromWireType:d=>d,toWireType:function(d,f){if("bigint"!=
typeof f&&"number"!=typeof f)throw null===f?f="null":(d=typeof f,f="object"===d||"array"===d||"function"===d?f.toString():""+f),new TypeError(`Cannot convert "${f}" to ${this.name}`);"number"==typeof f&&(f=BigInt(f));return f},argPackAdvance:8,readValueFromPointer:Ra(b,c>>>0,-1==b.indexOf("u")),bb:null})},K:function(a,b,c,d){b=Q(b>>>0);S(a>>>0,{name:b,fromWireType:function(f){return!!f},toWireType:function(f,g){return g?c:d},argPackAdvance:8,readValueFromPointer:function(f){return this.fromWireType(G[f>>>
0])},bb:null})},J:function(a){return S(a>>>0,Va)},B:function(a,b,c){b=Q(b>>>0);S(a>>>0,{name:b,fromWireType:d=>d,toWireType:(d,f)=>f,argPackAdvance:8,readValueFromPointer:Wa(b,c>>>0),bb:null})},s:function(a,b,c,d,f){a>>>=0;c>>>=0;b=Q(b>>>0);-1===f&&(f=4294967295);f=l=>l;if(0===d){var g=32-8*c;f=l=>l<<g>>>g}var k=b.includes("unsigned")?function(l,p){return p>>>0}:function(l,p){return p};S(a,{name:b,fromWireType:f,toWireType:k,argPackAdvance:8,readValueFromPointer:Ra(b,c,0!==d),bb:null})},o:function(a,
b,c){function d(g){return new f(F.buffer,J[g+4>>>2>>>0],J[g>>>2>>>0])}var f=[Int8Array,Uint8Array,Int16Array,Uint16Array,Int32Array,Uint32Array,Float32Array,Float64Array,BigInt64Array,BigUint64Array][b];c=Q(c>>>0);S(a>>>0,{name:c,fromWireType:d,argPackAdvance:8,readValueFromPointer:d},{nb:!0})},D:function(a,b){b=Q(b>>>0);var c="std::string"===b;S(a>>>0,{name:b,fromWireType:function(d){var f=J[d>>>2>>>0],g=d+4;if(c)for(var k=g,l=0;l<=f;++l){var p=g+l;if(l==f||0==G[p>>>0]){k=O(k,p-k);if(void 0===n)var n=
k;else n+=String.fromCharCode(0),n+=k;k=p+1}}else{n=Array(f);for(l=0;l<f;++l)n[l]=String.fromCharCode(G[g+l>>>0]);n=n.join("")}W(d);return n},toWireType:function(d,f){f instanceof ArrayBuffer&&(f=new Uint8Array(f));var g="string"==typeof f;if(!(g||f instanceof Uint8Array||f instanceof Uint8ClampedArray||f instanceof Int8Array))throw new R("Cannot pass non-string to std::string");var k=c&&g?La(f):f.length;var l=tb(4+k+1),p=l+4;J[l>>>2>>>0]=k;if(c&&g)P(f,G,p,k+1);else if(g)for(g=0;g<k;++g){var n=f.charCodeAt(g);
if(255<n)throw W(p),new R("String has UTF-16 code units that do not fit in 8 bits");G[p+g>>>0]=n}else for(g=0;g<k;++g)G[p+g>>>0]=f[g];null!==d&&d.push(W,l);return l},argPackAdvance:8,readValueFromPointer:Ua,bb(d){W(d)}})},x:function(a,b,c){b>>>=0;c>>>=0;c=Q(c);if(2===b){var d=Ya;var f=Za;var g=$a;var k=l=>na[l>>>1>>>0]}else 4===b&&(d=ab,f=bb,g=cb,k=l=>J[l>>>2>>>0]);S(a>>>0,{name:c,fromWireType:l=>{for(var p=J[l>>>2>>>0],n,q=l+4,v=0;v<=p;++v){var w=l+4+v*b;if(v==p||0==k(w))q=d(q,w-q),void 0===n?n=
q:(n+=String.fromCharCode(0),n+=q),q=w+b}W(l);return n},toWireType:(l,p)=>{if("string"!=typeof p)throw new R(`Cannot pass non-string to C++ string type ${c}`);var n=g(p),q=tb(4+n+b);J[q>>>2>>>0]=n/b;f(p,q+4,n+b);null!==l&&l.push(W,q);return q},argPackAdvance:8,readValueFromPointer:Ua,bb(l){W(l)}})},L:function(a,b){b=Q(b>>>0);S(a>>>0,{ob:!0,name:b,argPackAdvance:0,fromWireType:()=>{},toWireType:()=>{}})},ha:()=>1,u:function(a,b,c){b>>>=0;c>>>=0;a=U(a>>>0);b=eb(b,"emval::as");return fb(b,c,a)},w:function(a){a>>>=
0;return yb(()=>{a=U(a);return a.then(V)})},n:function(a,b,c,d){c>>>=0;d>>>=0;a=zb[a>>>0];b=U(b>>>0);return a(null,b,c,d)},j:function(a,b,c,d,f){c>>>=0;d>>>=0;f>>>=0;a=zb[a>>>0];b=U(b>>>0);c=Bb(c);return a(b,b[c],d,f)},b:Ta,A:function(a,b){b>>>=0;a=U(a>>>0);b=U(b);return a==b},m:function(a){a>>>=0;if(0===a)return V(Cb());a=Bb(a);return V(Cb()[a])},i:function(a,b,c){b=Eb(a,b>>>0);var d=b.shift();a--;var f="return function (obj, func, destructorsRef, args) {\n",g=0,k=[];0===c&&k.push("obj");for(var l=
["retType"],p=[d],n=0;n<a;++n)k.push("arg"+n),l.push("argType"+n),p.push(b[n]),f+=`  var arg${n} = argType${n}.readValueFromPointer(args${g?"+"+g:""});\n`,g+=b[n].argPackAdvance;f+=`  var rv = ${1===c?"new func":"func.call"}(${k.join(", ")});\n`;d.ob||(l.push("emval_returnValue"),p.push(fb),f+="  return emval_returnValue(retType, destructorsRef, rv);\n");l.push(f+"};\n");a=Gb(l)(...p);c=`methodCaller<(${b.map(q=>q.name).join(", ")}) => ${d.name}>`;return Db(Fb(c,a))},r:function(a,b){b>>>=0;a=U(a>>>
0);b=U(b);return V(a[b])},e:function(a){a>>>=0;9<a&&(T[a+1]+=1)},t:function(){return V([])},l:function(a){a=U(a>>>0);for(var b=Array(a.length),c=0;c<a.length;c++)b[c]=a[c];return V(b)},f:function(a){return V(Bb(a>>>0))},k:function(){return V({})},h:function(a){a>>>=0;for(var b=U(a);b.length;){var c=b.pop();b.pop()(c)}Ta(a)},g:function(a,b,c){b>>>=0;c>>>=0;a=U(a>>>0);b=U(b);c=U(c);a[b]=c},c:function(a,b){b>>>=0;a=eb(a>>>0,"_emval_take_value");a=a.readValueFromPointer(b);return V(a)},W:function(a,b){a=
-9007199254740992>a||9007199254740992<a?NaN:Number(a);b>>>=0;a=new Date(1E3*a);I[b>>>2>>>0]=a.getUTCSeconds();I[b+4>>>2>>>0]=a.getUTCMinutes();I[b+8>>>2>>>0]=a.getUTCHours();I[b+12>>>2>>>0]=a.getUTCDate();I[b+16>>>2>>>0]=a.getUTCMonth();I[b+20>>>2>>>0]=a.getUTCFullYear()-1900;I[b+24>>>2>>>0]=a.getUTCDay();I[b+28>>>2>>>0]=(a.getTime()-Date.UTC(a.getUTCFullYear(),0,1,0,0,0,0))/864E5|0},X:function(a,b){a=-9007199254740992>a||9007199254740992<a?NaN:Number(a);b>>>=0;a=new Date(1E3*a);I[b>>>2>>>0]=a.getSeconds();
I[b+4>>>2>>>0]=a.getMinutes();I[b+8>>>2>>>0]=a.getHours();I[b+12>>>2>>>0]=a.getDate();I[b+16>>>2>>>0]=a.getMonth();I[b+20>>>2>>>0]=a.getFullYear()-1900;I[b+24>>>2>>>0]=a.getDay();I[b+28>>>2>>>0]=(Z(a.getFullYear())?Hb:Ib)[a.getMonth()]+a.getDate()-1|0;I[b+36>>>2>>>0]=-(60*a.getTimezoneOffset());var c=(new Date(a.getFullYear(),6,1)).getTimezoneOffset(),d=(new Date(a.getFullYear(),0,1)).getTimezoneOffset();I[b+32>>>2>>>0]=(c!=d&&a.getTimezoneOffset()==Math.min(d,c))|0},Y:function(a){a>>>=0;var b=new Date(I[a+
20>>>2>>>0]+1900,I[a+16>>>2>>>0],I[a+12>>>2>>>0],I[a+8>>>2>>>0],I[a+4>>>2>>>0],I[a>>>2>>>0],0),c=I[a+32>>>2>>>0],d=b.getTimezoneOffset(),f=(new Date(b.getFullYear(),6,1)).getTimezoneOffset(),g=(new Date(b.getFullYear(),0,1)).getTimezoneOffset(),k=Math.min(g,f);0>c?I[a+32>>>2>>>0]=Number(f!=g&&k==d):0<c!=(k==d)&&(f=Math.max(g,f),b.setTime(b.getTime()+6E4*((0<c?k:f)-d)));I[a+24>>>2>>>0]=b.getDay();I[a+28>>>2>>>0]=(Z(b.getFullYear())?Hb:Ib)[b.getMonth()]+b.getDate()-1|0;I[a>>>2>>>0]=b.getSeconds();I[a+
4>>>2>>>0]=b.getMinutes();I[a+8>>>2>>>0]=b.getHours();I[a+12>>>2>>>0]=b.getDate();I[a+16>>>2>>>0]=b.getMonth();I[a+20>>>2>>>0]=b.getYear();a=b.getTime();return BigInt(isNaN(a)?-1:a/1E3)},U:function(){return-52},V:function(){},N:function(a,b,c,d){c>>>=0;d>>>=0;var f=(new Date).getFullYear(),g=new Date(f,0,1),k=new Date(f,6,1);f=g.getTimezoneOffset();var l=k.getTimezoneOffset();J[a>>>0>>>2>>>0]=60*Math.max(f,l);I[b>>>0>>>2>>>0]=Number(f!=l);a=p=>p.toLocaleTimeString(void 0,{hour12:!1,timeZoneName:"short"}).split(" ")[1];
g=a(g);k=a(k);l<f?(P(g,G,c,17),P(k,G,d,17)):(P(g,G,d,17),P(k,G,c,17))},v:()=>{xa("")},d:function(a,b,c){a>>>=0;b=Kb(b>>>0,c>>>0);return Ea[a](...b)},I:function(a,b,c){a>>>=0;b=Kb(b>>>0,c>>>0);return Ea[a](...b)},H:()=>Date.now(),O:function(){return 4294901760},q:()=>performance.now(),M:function(a){a>>>=0;var b=G.length;if(4294901760<a)return!1;for(var c=1;4>=c;c*=2){var d=b*(1+.2/c);d=Math.min(d,a+100663296);var f=Math;d=Math.max(a,d);a:{f=(f.min.call(f,4294901760,d+(65536-d%65536)%65536)-la.buffer.byteLength+
65535)/65536;try{la.grow(f);sa();var g=1;break a}catch(k){}g=void 0}if(g)return!0}return!1},$:function(a,b){a>>>=0;b>>>=0;var c=0;Nb().forEach((d,f)=>{var g=b+c;f=J[a+4*f>>>2>>>0]=g;for(g=0;g<d.length;++g)F[f++>>>0]=d.charCodeAt(g);F[f>>>0]=0;c+=d.length+1});return 0},aa:function(a,b){a>>>=0;b>>>=0;var c=Nb();J[a>>>2>>>0]=c.length;var d=0;c.forEach(f=>d+=f.length+1);J[b>>>2>>>0]=d;return 0},z:()=>52,F:function(){return 52},_:function(){return 70},E:function(a,b,c,d){b>>>=0;c>>>=0;d>>>=0;for(var f=
0,g=0;g<c;g++){var k=J[b>>>2>>>0],l=J[b+4>>>2>>>0];b+=8;for(var p=0;p<l;p++){var n=G[k+p>>>0],q=Ob[a];0===n||10===n?((1===a?ka:D)(Ka(q,0)),q.length=0):q.push(n)}f+=l}J[d>>>2>>>0]=f;return 0},fa:Sb,p:function(a,b,c,d){return Sb(a>>>0,b>>>0,c>>>0,d>>>0)}},X=function(){function a(c){X=c.exports;X=ib();X=Wb();la=X.ja;sa();ua.unshift(X.ka);K--;0==K&&(null!==wa&&(clearInterval(wa),wa=null),L&&(c=L,L=null,c()));return X}var b={a:Vb};K++;if(e.instantiateWasm)try{return e.instantiateWasm(b,a)}catch(c){D(`Module.instantiateWasm callback failed with error: ${c}`),
m(c)}Da(b,function(c){a(c.instance)}).catch(m);return{}}(),db=a=>(db=X.la)(a);e._OrtInit=(a,b)=>(e._OrtInit=X.ma)(a,b);e._OrtGetLastError=(a,b)=>(e._OrtGetLastError=X.na)(a,b);e._OrtCreateSessionOptions=(a,b,c,d,f,g,k,l,p,n)=>(e._OrtCreateSessionOptions=X.oa)(a,b,c,d,f,g,k,l,p,n);e._OrtAppendExecutionProvider=(a,b)=>(e._OrtAppendExecutionProvider=X.pa)(a,b);e._OrtAddFreeDimensionOverride=(a,b,c)=>(e._OrtAddFreeDimensionOverride=X.qa)(a,b,c);
e._OrtAddSessionConfigEntry=(a,b,c)=>(e._OrtAddSessionConfigEntry=X.ra)(a,b,c);e._OrtReleaseSessionOptions=a=>(e._OrtReleaseSessionOptions=X.sa)(a);e._OrtCreateSession=(a,b,c)=>(e._OrtCreateSession=X.ta)(a,b,c);e._OrtReleaseSession=a=>(e._OrtReleaseSession=X.ua)(a);e._OrtGetInputOutputCount=(a,b,c)=>(e._OrtGetInputOutputCount=X.va)(a,b,c);e._OrtGetInputName=(a,b)=>(e._OrtGetInputName=X.wa)(a,b);e._OrtGetOutputName=(a,b)=>(e._OrtGetOutputName=X.xa)(a,b);e._OrtFree=a=>(e._OrtFree=X.ya)(a);
e._OrtCreateTensor=(a,b,c,d,f,g)=>(e._OrtCreateTensor=X.za)(a,b,c,d,f,g);e._OrtGetTensorData=(a,b,c,d,f)=>(e._OrtGetTensorData=X.Aa)(a,b,c,d,f);e._OrtReleaseTensor=a=>(e._OrtReleaseTensor=X.Ba)(a);e._OrtCreateRunOptions=(a,b,c,d)=>(e._OrtCreateRunOptions=X.Ca)(a,b,c,d);e._OrtAddRunConfigEntry=(a,b,c)=>(e._OrtAddRunConfigEntry=X.Da)(a,b,c);e._OrtReleaseRunOptions=a=>(e._OrtReleaseRunOptions=X.Ea)(a);e._OrtCreateBinding=a=>(e._OrtCreateBinding=X.Fa)(a);
e._OrtBindInput=(a,b,c)=>(e._OrtBindInput=X.Ga)(a,b,c);e._OrtBindOutput=(a,b,c,d)=>(e._OrtBindOutput=X.Ha)(a,b,c,d);e._OrtClearBoundOutputs=a=>(e._OrtClearBoundOutputs=X.Ia)(a);e._OrtReleaseBinding=a=>(e._OrtReleaseBinding=X.Ja)(a);e._OrtRunWithBinding=(a,b,c,d,f)=>(e._OrtRunWithBinding=X.Ka)(a,b,c,d,f);e._OrtRun=(a,b,c,d,f,g,k,l)=>(e._OrtRun=X.La)(a,b,c,d,f,g,k,l);e._OrtEndProfiling=a=>(e._OrtEndProfiling=X.Ma)(a);e._JsepOutput=(a,b,c)=>(e._JsepOutput=X.Na)(a,b,c);
e._JsepGetNodeName=a=>(e._JsepGetNodeName=X.Oa)(a);var tb=e._malloc=a=>(tb=e._malloc=X.Pa)(a),W=e._free=a=>(W=e._free=X.Qa)(a),Xb=a=>(Xb=X.Sa)(a),Yb=a=>(Yb=X.Ta)(a),Zb=()=>(Zb=X.Ua)(),wb=a=>(wb=X.Wa)(a),kb=()=>(kb=X.Xa)(),vb=a=>(vb=X.Ya)(a),xb=()=>(xb=X.Za)();e.___start_em_js=837952;e.___stop_em_js=838113;function Wb(){var a=X;a=Object.assign({},a);var b=c=>d=>c(d)>>>0;a.la=b(a.la);a.Pa=b(a.Pa);a.Ta=b(a.Ta);a.Ua=(c=>()=>c()>>>0)(a.Ua);return a}e.stackSave=()=>Zb();e.stackRestore=a=>Xb(a);
e.stackAlloc=a=>Yb(a);e.UTF8ToString=O;e.stringToUTF8=(a,b,c)=>P(a,G,b,c);e.lengthBytesUTF8=La;var $b;L=function ac(){$b||bc();$b||(L=ac)};function bc(){if(!(0<K)){if(e.preRun)for("function"==typeof e.preRun&&(e.preRun=[e.preRun]);e.preRun.length;){var a=e.preRun.shift();ta.unshift(a)}for(;0<ta.length;)ta.shift()(e);if(!(0<K||$b||($b=!0,e.calledRun=!0,E))){for(;0<ua.length;)ua.shift()(e);for(aa(e);0<va.length;)va.shift()(e)}}}bc();


  return readyPromise
}
);
})();
if (typeof exports === 'object' && typeof module === 'object')
  module.exports = ortWasm;
else if (typeof define === 'function' && define['amd'])
  define([], () => ortWasm);
